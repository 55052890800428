import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Horario } from '../_models/horario';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class HorarioService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getHorarios() {
    return this.http.get<Horario[]>(this.baseUrl + 'horario/');
  }

  getHorario(id: string) {
    return this.http.get<Horario>(this.baseUrl+'horario/'+id);
  }

  deleteHorario(id: number | string) {
    return this.http.delete(this.baseUrl+'horario/'+id);
  }

  createHorario(horario: Horario) {
    return this.http.post(this.baseUrl+'horario/', horario);
  }

  updateHorario(horario: Horario){
    return this.http.put(this.baseUrl+'horario/'+horario.id, horario);
  }
}
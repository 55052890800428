import { Component, OnInit } from '@angular/core';
import { JugadorService } from '../../_services/jugador.service';
import { JugadorEquipoService } from '../../_services/jugador-equipo.service';
import { ImpregService } from '../../_services/impreg.service';
import { EquipoService } from '../../_services/equipo.service';
import { CategoriaService } from 'src/app/_services/categoria.service';
import { Jugador } from '../../_models/jugador';
import { Categoria } from '../../_models/categoria';
import { Jugador_Equipo } from '../../_models/jugador_equipo';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Impreg } from '../../_models/impreg';
import Swal from 'sweetalert2';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Equipo } from 'src/app/_models';
import { Security } from 'src/app/_helpers/super-seguridad';
import { AuthGuard } from '../login/auth.guard';
import { Registros } from 'src/app/_models/registros';

import {CroppedEvent} from 'ngx-photo-editor';

declare var $ :any;

@Component({
  selector: 'app-jugador',
  templateUrl: './jugador.component.html',
  styleUrls: ['./jugador.component.css']
})

export class JugadorComponent implements OnInit {

  jugadores: Jugador[];                 // Lista de jugadores obtenida de getJugadores()
  jugador: Jugador;               // Jugador que es Registrado o Actualizado
  jugador_imgs: Jugador;
  identificacion: File;           // File de identificación del jugador
  identificacion_name: string;
  foto: File;                     // File de fotografía del jugador
  foto_name: string;
  boton: string;                  // Nombre del botón html: Registrar o Actualizar Jugador
  actualizar = false;             // Identificar cuando se debe Registrar o Actualizar un Jugador
  modalTitleText: string;         // Nombre de la ventana para Registrar o Actualizar Jugador
  loading = false;
  loading_create = false;
  loadingCategoria = false;
  loadingEquipo = false;

  categorias: Categoria[];
  categoriaSeleccionada: number;

  impreg: Impreg[];          // Imprimir registros
  impreg2 = [];
  registros: Registros;
  textButtonRegistros = "";

  id_equipo: number;        // Equipo del delegado
  rol: string;
  jugador_equipo: Jugador_Equipo[];
  equipos: Equipo[];
  equipoSeleccionado: number;
  isAdmin: boolean;           // es administrador
  isDelegate: boolean;          // es delegado

  isAdministrador: boolean;
  isLogged: boolean;
  searchText;

  sinJugadores = false;

  dob = '00';  // Día de nacimiento
  mob = '00';  // Mes de nacimiento
  yob = '0000';  // Año de nacimiento

  textButton = "";

  pp: any;

  imageChangedEvent: any;
  base64: any;
  imageChangedEventIfe: any;
  base64Ife: any;

  bSelectAll = true;
  strSelectAll = "Seleccionar Todos!";
  
  constructor(
    private jugadorService: JugadorService, 
    private jugadorEquipoService: JugadorEquipoService, 
    private impregService: ImpregService,
    private equipoService: EquipoService,
    private categoriaService: CategoriaService,
    private formBuilder: FormBuilder,
    private security: Security,
    private authGuard: AuthGuard,
    private router: Router,
    private route: ActivatedRoute
    ){ 
      this.newJugador(this.jugador);
      this.id_equipo = +localStorage.getItem('id_equipo');
      this.rol = localStorage.getItem('rol');

      if(this.rol == 'ADMINISTRADOR'){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }
  }

  ngOnInit() {
    this.isAdministrador = this.security.puedeVer();
    this.isLogged = this.authGuard.renderOpcional();
    this.filtrarEquipos();
  }

  getCategorias() {
    // this.loading = true;
    this.loadingCategoria = true;
    this.categoriaService.getCategorias().subscribe(
        res => {
          this.categorias = res;
          // this.loading = false;
          this.loadingCategoria = false;
        },
        err => console.error(err)
      );
  }

  /*
    Funciones CRUDL con el servicio JugadorService:
  */

  getJugadores(id: number) {
    this.id_equipo = id;
    this.loading = true; 
    this.jugadores = [];
    this.impreg = [];
    this.impreg2 = [];
    this.jugadorService.getJugadores(this.id_equipo).subscribe(
      // this.jugadorService.getJugadores().subscribe(
        res => {
          // this.getJugadorEquipo(res);
          this.jugadores = res;

          // REGISTROS
          this.impreg = new Array<Impreg>(this.jugadores.length);
          for(var i = 0; i < this.impreg.length; i++){
            this.impreg[i] = new Impreg(this.jugadores[i][0]);
          }
          
          this.loading = false;
          console.log(this.jugadores)
          console.log(this.impreg)
        },
        err => console.error(err)
      );
  }

  getJugadores2(){
    this.getJugadores(this.equipoSeleccionado);
  }

  getJugador(id_jugador, tipo){
    Swal.showLoading()
    this.loading = true;
    this.jugadorService.getJugadorImg(id_jugador).subscribe(
        res => {
          this.jugador_imgs = res;
          this.loading = false;

          if(tipo == 'identificacion'){
            Swal.fire({
              imageUrl: this.jugador_imgs.identificacion,
              imageClass: './jugador.component.css',
            })
            let timerInterval;

          }else{
            Swal.fire({
              imageUrl: this.jugador_imgs.foto,
              imageClass: './jugador.component.css',
            })
          }
          
        },
        err => console.error(err)
      );
  }

  createJugador() {
    if(this.validarJugador(this.jugador)){
      this.loading_create = true; 
      this.textButton = 'Registrando...';
      this.jugadorService.createJugador(this.jugador).subscribe(
        res => {
          $("#jugadorModal").modal("hide");
          this.newJugador(this.jugador);
          this.getJugadores(this.id_equipo);
          Swal.fire(
            'Registrado',
            'El jugador ' + this.jugador.nombre_jugador + ' ha sido registrado',
            'success'
          )
          this.loading_create = false;
          this.imageChangedEvent = null;
          this.base64 = null;
          this. imageChangedEventIfe = null;
          this.base64Ife = null;
        },
        error => {
          // pending to show error
          console.error(error);
          this.loading_create = false;
        }
      );
    }else{
      console.log("No válido");
    }
  }

  actualizarJugador(jugador: Jugador) {
      this.jugador = jugador;

    console.log(this.jugador)
      this.yob = this.jugador.fecha_nacimiento.toString().substring(0,4);
      this.mob = this.jugador.fecha_nacimiento.toString().substring(5,7);
      this.dob = this.jugador.fecha_nacimiento.toString().substring(8,20);

      this.identificacion_name = 'Cambiar identificación...';
      this.foto_name = 'Cambiar fotografía...';
      this.actualizar = true;
      this.boton = 'Actualizar Jugador'
      this.modalTitleText = 'Editar Jugador: ' + jugador.nombre_jugador;
      this.loading_create = false; 
      $("#jugadorModal").modal("show");
  }

  updateJugador() {
    this.loading_create = true; 
    if(this.validarJugador(this.jugador)){
      this.jugadorService.updateJugador(this.jugador.id, this.jugador, this.id_equipo).subscribe(
          res => {
            Swal.fire(
              'Actualizado!',
              'El jugador ' + this.jugador.nombre_jugador + ' ha sido actualizado',
              'success'
            )
            $("#jugadorModal").modal("hide");
            this.newJugador(this.jugador);
            this.getJugadores(this.id_equipo);
            this.loading_create = false; 
          },
          err => console.error(err)
        );
    }
    this.getJugadores(this.id_equipo);
  }

  deleteJugador(jugador: Jugador){
    Swal.fire({
      title: "Eliminar Jugador",
      text: "Estás seguro que deseas eliminar al jugador " + jugador[1] + " ?", 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí. eliminar!'
    }).then((result) => {
      if(result.value){
        this.jugadorService.deleteJugador(jugador[0]).subscribe(
          res => {
            Swal.fire(
              'Eliminado!',
              'El jugador ' + jugador[1] + ' ha sido eliminado',
              'success'
            )
            this.getJugadores(this.id_equipo);
          },
          err => console.error(err)
        )
      }
    });
    // this.getJugadores(this.id_equipo);
  }

  getJugadorEquipo(jugadores_equipos: Jugador[]) {
    this.loading = true; 
    this.jugador_equipo = [];
    this.impreg = [];
    this.impreg2 = [];
    this.jugadorEquipoService.getJugadorEquipo().subscribe(
        res => {
          this.jugador_equipo = res;
          
          for(var i=0; i<jugadores_equipos.length;i++){
            for(var j=0; j<this.jugador_equipo.length;j++){
              if(jugadores_equipos[i].id == this.jugador_equipo[j].id_jugador){
                if(this.id_equipo == this.jugador_equipo[j].id_equipo){
                  this.jugadores.push(jugadores_equipos[i]);
                }
              }
            }
          }

          // REGISTROS
          this.impreg = new Array<Impreg>(this.jugadores.length);
          for(var i = 0; i < this.impreg.length; i++){
            this.impreg[i] = new Impreg(this.jugadores[i].id);
          }

          this.loading = false;
        },
        err => console.error(err)
      );
  }

  filtrarEquipos(){
    if(this.isAdmin){
      //this.getEquipos();

      this.getCategorias();
      this.isDelegate = true;

      this.categoriaSeleccionada = +this.route.snapshot.paramMap.get('id_categoria');
      console.log(this.categoriaSeleccionada);
      console.log(this.equipoSeleccionado);
      if(this.categoriaSeleccionada != 0){
        this.getEquipos();
        this.equipoSeleccionado = +this.route.snapshot.paramMap.get('id');
        console.log(this.equipoSeleccionado);
        this.getJugadores2();
      }

    }else{
      this.equipoSeleccionado = this.id_equipo;
      this.getJugadores(this.id_equipo);
      this.isDelegate = true;
    }
  }

  getEquipos() {
    if(this.categoriaSeleccionada != null){
      // this.loading = true;
      // this.flag = false;
      this.loadingEquipo = true;
      this.equipos = [];
      this.equipoService.getEquipoCategoria(this.categoriaSeleccionada).subscribe(
          res => {
            this.equipos = res;
            // this.loading = false;
            
            // this.loading = false;
            // this.flag = true;
            this.loadingEquipo = false;
          },
          err => console.error(err)
        );
    }
  }

  /*
    Funciones auxiliares
  */

  // Inicializa al jugador y sus archivos con valores vacíos
  newJugador(jugador: Jugador){
    this.jugador = { 
      id: 0,
      nombre_jugador: '',
      apellidos_jugador: '',
      fecha_nacimiento: new Date(),
      domicilio: '',
      padecimientos: '',
      identificacion: '',
      foto: '',
      estatus: 1,
      id_usuario: localStorage.getItem('id'),
      rol_operador: localStorage.getItem('rol'),
      equipo_seleccionado: this.equipoSeleccionado,
      jugador_equipo: []
    }
    this.identificacion = null;
    this.identificacion_name = 'Seleccionar identificación...';
    this.foto = null;
    this.foto_name = 'Seleccionar fotografía...';
    this.dob = '00';
    this.mob = '00';
    this.yob = '0000';
  }

  // Al cargar la identificación en el html, la convierte a base64 y asigna a jugador.identificacion
  identificacionSelected(event){
    this.identificacion = <File> event.target.files[0];
    this.identificacion_name = this.identificacion.name;
    this.jugador.identificacion = this.convertIdentificacion(this.identificacion,this.jugador);
  }

  // Al cargar la fotografía en el html, la convierte a base64 y asigna a jugador.identificacion
  fotoSelected(event){
    this.foto = <File> event.target.files[0];
    this.foto_name = this.foto.name;
    this.jugador.foto = this.convertFoto(this.foto, this.jugador);
  }

  // Convierte imagen de identificación a base64
  convertIdentificacion(file,jugador: Jugador): any {
    let reader = new FileReader();
    let me: any;
    reader.readAsDataURL(file);
    reader.onload = function () {
      me = reader.result;
      jugador.identificacion = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  // Convierte imagen de fotografía a base64
  convertFoto(file,jugador: Jugador): any {
    let reader = new FileReader();
    let me: any;
    reader.readAsDataURL(file);
    reader.onload = function () {
      me = reader.result;
      jugador.foto = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  // Muestra la identificación del jugador en una ventana emergente
  mostrarIdentificacion(identificacion: string){
    this.getJugador(identificacion, 'identificacion');
  }

  // Muestra la identificación del jugador en una ventana emergente
  mostrarFotografia(foto: string){
    this.getJugador(foto, 'foto');
  }

  // Muestra la ventana emergente de Registro o Actualización
  public openPostModal(){
    this.newJugador(this.jugador);
    this.modalTitleText = 'Registrar Jugador';
    this.textButton = 'Registrar';
    this.boton = 'Registrar Jugador';
    this.loading_create = false;
    $("#jugadorModal").modal("show");
  }

  // Validar campos del jugador a Registrar o Actualizar
  validarJugador(jugador: Jugador): boolean{
    // var fecha_nacimiento_min = new Date("2005-01-01");
    // var fecha_nacimiento_max = new Date("1955-01-01");

    //VALIDAR FECHA
    if(this.dob == '30' || this.dob == '31'){
      if(this.mob == '02'){
        this.dob = '29';
      }
    }
    if(this.dob == '31'){
      if(this.mob == '04' || this.mob == '06' || this.mob == '09' || this.mob == '11'){
        this.dob = '30';
      }
    }
    this.jugador.fecha_nacimiento = new Date(this.yob+"-"+this.mob+"-"+this.dob);
    
    if (this.tiene_caracteres(jugador.nombre_jugador) || this.tiene_caracteres(jugador.apellidos_jugador)) {
      Swal.fire({
        type: 'error',
        title: 'Error!',
        text: 'El nombre y apellidos solo pueden contener letras!'
      })
      return false;
    }else if(jugador.nombre_jugador=='' || jugador.apellidos_jugador=='' || jugador.domicilio==''  || jugador.identificacion==''  || jugador.foto=='' ){
      Swal.fire({
        type: 'error',
        title: 'Error!',
        text: 'Favor de completar todos los campos!'
      })
    // }else if(fecha_nacimiento_jugador > fecha_nacimiento_min || fecha_nacimiento_jugador < fecha_nacimiento_max){
    //   Swal.fire({
    //     type: 'error',
    //     title: 'Error!',
    //     text: 'El jugador debe tener entre 15 y 65 años de edad!'
    //   })
    //   return false;
    }else{
      return true;
    }
  }

  verDetalleJugador(jugador){
    this.router.navigate(['jugador/'+jugador[0]]);
    //window.open(window.location.href+"/"+jugador[0], '_blank');
  }

  // Valida si un string contiene números (validar nombre y apellidos del jugador)
  tiene_caracteres(texto: string): boolean{
    var caracteres="0123456789!#$%&/()=?¿¡,.;:{}[]+-";
    for(var i=0; i<texto.length; i++){
       if (caracteres.indexOf(texto.charAt(i),0)!=-1){
          return true;
       }
    }
    return false;
  }

  // Muestra la fecha en la que se actualiza la tabla de consulta
  fecha_actual(){
    let fecha_actual = new Date();
    let mes_letra: string;
    let mes_numero = (fecha_actual.getMonth()+1);
  
    switch(mes_numero){
      case 1:
        mes_letra = "Enero";
        break;
      case 2:
        mes_letra = "Febrero";
        break;
      case 3:
        mes_letra = "Marzo";
        break;
      case 4:
        mes_letra = "Abril";
        break;
      case 5:
        mes_letra = "Mayo";
        break;
      case 6:
        mes_letra = "Junio";
        break;
      case 7:
        mes_letra = "Julio";
        break;
      case 8:
        mes_letra = "Agosto";
        break;
      case 9:
        mes_letra = "Septiembre";
        break;
      case 10:
        mes_letra = "Octubre";
        break;
      case 11:
        mes_letra = "Noviembre";
        break;
      case 12:
        mes_letra = "Diciembre";
        break; 
      default:
          mes_letra = "n/a";
    }
  
    let fecha = fecha_actual.getDate() + '-' +  mes_letra + '-' + fecha_actual.getFullYear();
    let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();

    return fecha + ' ' + hora; 
  }

  // FILTRAR JUGADORES POR EQUIPO

  filtraJugadores(){

  }

  // IMPRIMIR REGISTROS

  registrarModal(){
    this.textButtonRegistros = "Imprimir";
    $("#registroModal").modal("show");
  }

  onSubmit(){
    console.log(this.impreg);

    for(var ip of this.impreg){
      if(ip.selected){
        this.impreg2.push(ip);
      }
    }
    this.impreg2;

    var ids:number[] = new Array(this.impreg2.length);
    for(var i=0; i<this.impreg2.length;i++){
      ids[i]=this.impreg2[i].id;
    }

    this.loading = true; 
    this.textButtonRegistros = "Imprimiendo...";
    this.impregService.createImpreg(ids).subscribe(
      res => {
        this.registros = res;
        // console.log(this.registros.registros);
        //this.showPDF(this.registros.registros);
        console.log(this.registros);
        this.downloadPDF(this.registros);
        $("#registroModal").modal("hide");
        this.impreg2 = [];
        this.impreg = [];
        this.getJugadores(this.id_equipo);
        Swal.fire(
          'Registros generados',
          'Los registros ha sido generados correctamente!',
          'success'
        );
        // this.equipoService.descargaRegistros(this.id_equipo);
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    );
    
  }

  downloadPDF(registros){
      for(var i = 0; i<registros.registros.length; i++){
        if(registros.registros[i] != null){
          this.showPDF("data:application/pdf;base64,"+registros.registros[i]);
        }
      }
      //this.showPDF("data:application/pdf;base64,"+registros.registros[i]);
  }

  showPDF(pdf_base64){
    console.log(pdf_base64);
    const linkSource = pdf_base64;
    const downloadLink = document.createElement("a");
    var file_name = "registros";
    for(var i = 0; i<this.equipos.length; i++){
      if(this.equipos[i].id == this.equipoSeleccionado){
        file_name = this.equipos[i].nombre_equipo;
      }
    }
    for(var i = 0; i<this.categorias.length; i++){
      if(this.categorias[i].id == this.categoriaSeleccionada){
        file_name = file_name + " - " + this.categorias[i].nombre_categoria;
      }
    }
    const fileName = file_name.trim() + ".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    for(var i = 0; i<this.impreg.length; i++){
        this.impreg[i].selected = 0;
        this.strSelectAll = "Seleccionar Todos!"
        this.bSelectAll = true;
    }

    return downloadLink;
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
    this.foto_name = "Fotografía Seleccionada!";
  }

  imageCropped(event: CroppedEvent) {
    this.base64 = event.base64;
    this.jugador.foto = this.base64; 
    // this.foto_name = "Fotografía Seleccionada!";
  }

  fileChangeEventIfe(event: any) {
    this.imageChangedEventIfe = event;
    this.identificacion_name = "Identificación Seleccionada!";
  }

  imageCroppedIfe(event: CroppedEvent) {
    this.base64Ife = event.base64;
    this.jugador.identificacion = this.base64Ife; 
    // this.identificacion_name = "Identificación Seleccionada!";
  }

  selectAll(){
    let selected = 1;
    if(this.bSelectAll == true){
      selected = 1;
      this.strSelectAll = "Deseleccionar Todos!"
      this.bSelectAll = false;
    }else{
      selected = 0;
      this.strSelectAll = "Seleccionar Todos!"
      this.bSelectAll = true;
    }
    for(var i = 0; i<this.impreg.length; i++){
      if(this.bSelectAll == true){
        this.impreg[i].selected = selected;
        
      }else{
        this.impreg[i].selected = selected;
        
      }
      
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { PartidoService } from '../../_services/partido.service';
import { CategoriaService} from '../../_services/categoria.service';
import { EquipoService } from '../../_services/equipo.service';
import { DatesHelper } from '../../_helpers/dates';
import { Partido, Equipo } from '../../_models';
import { Categoria } from 'src/app/_models/categoria';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import swal from 'sweetalert2'; 
import { HttpClient } from '@angular/common/http';
import { TipoTorneoService } from 'src/app/_services/tipo-torneo.service';
import { TorneoService } from 'src/app/_services/torneo.service';
import { CanchaService } from 'src/app/_services/cancha.service';
import {Security} from 'src/app/_helpers/super-seguridad'
import { AuthGuard } from '../login/auth.guard';
import { range } from 'rxjs';


declare var $ :any;

@Component({
  selector: 'app-partido',
  templateUrl: './partido.component.html',
  styleUrls: ['./partido.component.css'] 
})
export class PartidoComponent implements OnInit {
  
  // Atributos de clase
  estados = [
    { id: 1, descripcion: "Disputado" },
    { id: 2, descripcion: "Pendiente" },
    { id: 3, descripcion: "Suspendido" }
  ];

  partidos = [];
  canchas = [];
  torneos = [];
  tiposTorneo = [];
  categorias: Categoria[];

  partidoForm: FormGroup;
  loading = false;
  loadingCategoria = false;
  loadingTorneo = false;

  submitted = false;
  modalTitleText: String;
  postOrPutPartido;

  tipoTorneoSeleccionado: number;
  torneoSeleccionado: number;
  categoriaSeleccionada: number;
  estadoSeleccionado: String;

  equiposLocalTorneo: Equipo[];
  equiposVisitaTorneo: Equipo[];

  torneoSelected: any;
  equipoLocalSelected: Equipo;
  equipoVisitaSelected: Equipo;

  isAdministrador: boolean;
  isLogged: boolean;
  resultadoForm: FormGroup;
  postOrPutResultado: any;
  putResultado: any;
  modalResultadoLocal: string;
  modalResultadoVisita: string;

  searchText;

   // atributos agregado para generar partidos manualmente
  jornadas: number;
  jornadasA = []; 
  jornadaSeleccionada: number; 
  categoriaSeleccionadaModal: number;
  torneoSeleccionadoModal: number;
  equipos = [];
  partidoManualForm: FormGroup;

  // Penales
  penales_local: number;
  penales_visita: number;
  ambos_pierden: number;

  constructor(
      private partidoService: PartidoService,
      private tipoTorneoService: TipoTorneoService,
      private equipoService: EquipoService, 
      private categoriaService: CategoriaService,
      private canchaService: CanchaService,
      private torneoService: TorneoService,
      private formBuilder: FormBuilder,
      private datesHelper: DatesHelper,
      private http: HttpClient,
      private security: Security,
      private authGuard: AuthGuard
  ) { }

  // Funcion que se ejecuta al cargar el componente de Partido
  ngOnInit() {
    this.isAdministrador = this.security.puedeVer();
    this.isLogged = this.authGuard.renderOpcional();
    
    if(!localStorage.getItem('token') || localStorage.getItem('rol')=="DELEGADO"){
    this.getPartidoTiposTorneo();
    this.getPartidoCategorias();
    this.getPartidoCanchas();
    }
    else{ 
      this.getTiposTorneo();
      this.getCategorias();
      this.getCanchas();
    }

    // Inicializar campos de formulario con sus validaciones
    this.partidoForm = this.formBuilder.group({
      id: [''],
      id_tipo_torneo: ['', Validators.required],
      id_categoria: ['', Validators.required],
      id_torneo: [{value: null, disabled: true}, Validators.required],
      id_cancha: [{value: null, disabled: true}, Validators.required],
      id_equipo_local: [{value: null, disabled: true}, Validators.required],
      id_equipo_visita: [{value: null, disabled: true}, Validators.required],
      horario_partido: [{value: null, disabled: true}, Validators.required],
      goles_local: ['', ],
      goles_visita: ['',],
      jornada: ['', Validators.required],
      estado: "PENDIENTE",
      estatus: true
    })

    // form agregado para generar partidos manualmente
    this.partidoManualForm = this.formBuilder.group({
      id: [''],
      id_categoria: ['', Validators.required],
      id_torneo: ['', Validators.required],
      id_cancha: ['', Validators.required],
      id_equipo_local: ['', Validators.required],
      id_equipo_visita: ['', Validators.required],
      fecha: ['', Validators.required],
      hora: ['', Validators.required],
      horario_partido: [],
      goles_local: ['', ],
      goles_visita: ['',],
      jornada: ['', Validators.required],
      estado: "PENDIENTE",
      estatus: 1
    })
    
    this.resultadoForm = this.formBuilder.group({
      id: [''],
      goles_local: ['', Validators.required],
      goles_visita: ['',Validators.required],
      penales_local: [''],
      penales_visita: [''],
      ambos_pierden: [''],
      estado: "DISPUTADO",
      estatus: 1
    })
  }





  getPartidoCanchas() {
    this.canchaService.getPartidoCanchas().subscribe(
      res => {
        this.canchas = res;
      },
       err => console.error(err)
     );
  }

  getCanchas() {
    this.canchaService.getCanchas().subscribe(
      res => {
        this.canchas = res;
      },
       err => console.error(err)
     );
  }

  getPartidoTiposTorneo(){
    this.tipoTorneoService.getPartidoTipoTorneos().subscribe(
      res => {
        this.tiposTorneo = res;
      },
       err => console.error(err)
     );
  }

  getTiposTorneo(){
    this.tipoTorneoService.getTipoTorneos().subscribe(
      res => {
        this.tiposTorneo = res;
      },
       err => console.error(err)
     );
  }

  getTorneos() {
    this.loadingTorneo = true;
    this.torneos = [];

    this.jornadaSeleccionada = null;
    this.partidos = [];

    this.torneoService.getTorneosTabla().subscribe(
        res => {
          var torneos = res;
          for(var torneo of torneos){
            if(torneo.id_categoria == this.categoriaSeleccionada){
              this.torneos.push(torneo);
            }
          }
          if(this.torneos.length == 0){
            swal.fire({
              type: 'info',
              title: 'Aviso',
              text: 'No existen torneos en la categoría seleccionada'
            })
          }
          this.loadingTorneo = false;
        },
        err => console.error(err)
      );
      
  }

  tipoTorneoCategoriaSelectedF() {
    let tipo = this.partidoForm.controls['id_tipo_torneo'].value;
    let cat = this.partidoForm.controls['id_categoria'].value;

    if(tipo && cat){
      // call service to get torneo for id_tipo_torneo and id_categoria
      if(!localStorage.getItem('token') || localStorage.getItem('rol')=="DELEGADO"){
        console.log("Flujo de no loggeado");
        this.torneoService.getPartidoTorneosByTipoAndCategoria(tipo, cat).subscribe(
          res => {
            this.torneos = res;
            
            this.partidoForm.get('id_torneo').enable();
          },
          err => console.error(err)
        )
      }
      else{
      this.torneoService.getTorneosByTipoAndCategoria(tipo, cat).subscribe(
        res => {
          this.torneos = res;
          
          this.partidoForm.get('id_torneo').enable();
        },
        err => console.error(err)
      )
    }
    }else{
      this.partidoForm.get('id_torneo').disable();
    }
  }

  torneoSelectedF(){
    this.torneoSelected = this.partidoForm.controls['id_categoria'].value;
    this.equiposLocalTorneo = this.torneoSelected.equipos;
    this.partidoForm.get('id_equipo_local').enable();
    //this.partidoForm.controls['id_torneo'].setValue(this.torneoSelected.id);
  }

  equipoSelectedF(tipo: String){
    let local = this.partidoForm.controls['id_equipo_local'].value;
    let visita = this.partidoForm.controls['id_equipo_visita'].value;

    if(tipo == "local"){
      this.equipoLocalSelected = local;
      this.equiposVisitaTorneo = this.equiposLocalTorneo;
      //this.removeEquipo(this.equipoLocalSelected);
      this.partidoForm.get('id_equipo_visita').enable();
    }else{
      this.equipoVisitaSelected = visita;
    }

    if(local && visita)
      this.partidoForm.get('id_cancha').enable();    
  }

  canchaSelected(){
    this.partidoForm.get('horario_partido').enable();
  }

  removeEquipo(equipo: Equipo){
    this.equiposLocalTorneo.forEach( (item, index) => {
      if(item === equipo) this.equiposLocalTorneo.splice(index,1);
    });
  }
  
  getPartidoCategorias() {
    this.loadingCategoria = true;
    this.categoriaService.getCategoriasPartido().subscribe(
      res => {
        this.categorias = res;
        this.loadingCategoria = false;
      },
       err => console.error(err)
     );
    }

  getCategorias() {
   this.categoriaService.getCategorias().subscribe(
     res => {
       this.categorias = res;
     },
      err => console.error(err)
    );
   }

   muestraCategorias() {
    $("#categoriaSeleccionada").prop('disabled', false);
   }

  cargaTorneos(tipo: number, categoria: number){

    $("#torneoSeleccionado").prop('disabled', false);
    if(!localStorage.getItem('token') || localStorage.getItem('rol')=="DELEGADO"){
      this.torneoService.getPartidosTorneosByIdTipoAndIdCategoria(tipo, categoria).subscribe(
        res => {
          this.torneos = res;      
        },
        error => {
          console.error(error);
        }
      );
    }
    else{
    this.torneoService.getTorneosByIdTipoAndIdCategoria(tipo, categoria).subscribe(
      res => {
        this.torneos = res;      
      },
      error => {
        console.error(error);
      }
    );
    }
  }

  muestraEstados() {
    $("#estadoSeleccionado").prop('disabled', false);
  }

  getPartidosFiltrados(){
    this.loading = true;
    this.partidoService.getPartidosFiltrados(this.torneoSeleccionado, this.categoriaSeleccionada, this.estadoSeleccionado)
    .pipe(first())
    .subscribe(partidos => {
        this.partidos = partidos
        this.loading = false;
      },
        error => {
          console.error(error);
        }
    );
  }

  buscarPartidos(){
    if(this.torneoSeleccionado == undefined){
        swal.fire({
          title: 'Por favor selecciona un torneo.',
          text: "",
          type: 'warning',
          confirmButtonText: 'ok'
        })
        return;
    }
    if(this.categoriaSeleccionada == undefined){
      swal.fire({
        title: 'Por favor selecciona una categoria.',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
  }

  if(this.estadoSeleccionado == undefined){
    swal.fire({
      title: 'Por favor selecciona un estado.',
      text: "",
      type: 'warning',
      confirmButtonText: 'ok'
    })
    return;
}

    this.loading = true;
    if(!localStorage.getItem('token') || localStorage.getItem('rol')=="DELEGADO"){
      this.partidoService.getConsultaPartidosFiltrados(this.torneoSeleccionado, this.categoriaSeleccionada, this.estadoSeleccionado)
      .pipe(first())
      .subscribe(partidos => {
          this.partidos = partidos
          this.loading = false;
        },
          error => {
            console.error(error);
          }
          );
    }else{
    this.partidoService.getPartidosFiltrados(this.torneoSeleccionado, this.categoriaSeleccionada, this.estadoSeleccionado)
    .pipe(first())
    .subscribe(partidos => {
        this.partidos = partidos
        this.loading = false;
      },
        error => {
          console.error(error);
        }
        );
    }
  }

  fecha_actual(){
    return this.datesHelper.fecha_actual();
  }

  // Getter para acceder a los campos del formulario
  // get f() { return this.partidoForm.controls; }
  get f() { return this.partidoManualForm.controls; }

  public openPostModal(){
    this.partidoForm.reset();
    this.torneoSeleccionadoModal = null;
    this.modalTitleText = 'Registrar partido';
    $("#partidoModal").modal("show");
  }

  // Crear-Actualizar partido (Submit del formulario de alta y editar partido)
  onSubmit(){
    console.log(this.partidoForm.value)
    this.submitted = true;

    if(this.partidoForm.invalid){
      swal.fire({
        title: 'Todos los campos deben ser llenados',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }

    this.partidoForm.controls['id_torneo'].setValue(this.partidoForm.value.id_torneo.id);
    this.partidoForm.controls['id_equipo_local'].setValue(this.partidoForm.value.id_equipo_local.id);
    this.partidoForm.controls['id_equipo_visita'].setValue(this.partidoForm.value.id_equipo_visita.id);

    
    this.partidoForm.controls['estatus'].setValue(1);

    console.log(this.partidoForm.value);

    this.loading = true;
    if (this.modalTitleText === 'Alta de partido')
      this.postOrPutPartido = this.partidoService.postPartido(this.partidoForm.value)
    else
      this.postOrPutPartido = this.partidoService.putPartido(this.partidoForm.value);
    
    this.postOrPutPartido
      .pipe(first())
      .subscribe(
        response => {
          swal.fire({
            title: 'Correcto!',
            text: response.message,
            type: 'success',
            confirmButtonText: 'ok'
          }),
          this.getPartidos();
          $("#partidoModal").modal("hide");
          this.partidoForm.reset();
          this.submitted = false;
          this.loading = false;
         
        },
        error => {
          // pending to show error
          console.error(error);
        }
    )
  }

  // Obtener partidos
  private getPartidos() {
    this.partidoService.getAll()
      .pipe(first())
      .subscribe(partidos => this.partidos = partidos);
    }

  // Actualizar partido
  public updatePartido(partido: Partido){

    this.modalTitleText = 'Editar Partido: ' + partido.equipo_local.nombre_equipo + ' vs ' + partido.equipo_visita.nombre_equipo;

    this.partidoForm.controls['id'].setValue(partido.id);
    this.partidoForm.controls['id_tipo_torneo'].setValue(partido.torneo.id_tipo_torneo);
    this.partidoForm.controls['id_categoria'].setValue(partido.torneo.categoria.id);
    this.partidoForm.controls['id_equipo_local'].setValue(partido.id_equipo_local);
    this.partidoForm.controls['id_equipo_visita'].setValue(partido.id_equipo_visita);
    this.partidoForm.controls['jornada'].setValue(partido.jornada);
    this.partidoForm.controls['id_cancha'].setValue(partido.cancha.id);
    this.partidoForm.controls['horario_partido'].setValue(partido.horario_partido);

    $("#partidoModal").modal("show");
  }


  // Establecer resultado de partido en estado pendiente
  public setResultado(partido: Partido){

    this.modalTitleText = 'Establecer Resultado Partido: \n' + partido.equipo_local.nombre_equipo + ' vs ' + partido.equipo_visita.nombre_equipo;

    this.modalResultadoLocal = partido.equipo_local.nombre_equipo;
    this.modalResultadoVisita = partido.equipo_visita.nombre_equipo;
    this.resultadoForm.controls['id'].setValue(partido.id);

    $("#resultadoModal").modal("show");
  }

  onSubmitResultado(){
    console.log(this.resultadoForm.value);
    this.submitted = true;

    if(this.resultadoForm.controls['ambos_pierden'].value == true){
      this.resultadoForm.controls['estatus'].setValue(4);
      this.resultadoForm.controls['goles_local'].setValue(0);
      this.resultadoForm.controls['goles_visita'].setValue(0);
    }else{
      if(this.resultadoForm.controls['penales_local'].value == true){
        if(this.resultadoForm.controls['goles_local'].value != this.resultadoForm.controls['goles_visita'].value){
          swal.fire({
            title: 'El marcador debe ser empate para seleccionar penales!',
            text: "",
            type: 'warning',
            confirmButtonText: 'ok'
          })
          return;
        }else{
          this.resultadoForm.controls['estatus'].setValue(2);
        }
      }
  
      if(this.resultadoForm.controls['penales_visita'].value == true){
        if(this.resultadoForm.controls['goles_local'].value != this.resultadoForm.controls['goles_visita'].value){
          swal.fire({
            title: 'El marcador debe ser empate para seleccionar penales!',
            text: "",
            type: 'warning',
            confirmButtonText: 'ok'
          })
          return;
        }else{
          this.resultadoForm.controls['estatus'].setValue(3);
        }
      }
  
      if(this.resultadoForm.controls['penales_local'].value == true && this.resultadoForm.controls['penales_visita'].value == true){
        swal.fire({
          title: 'Seleccionar solo un equipo que ganó en penales!',
          text: "",
          type: 'warning',
          confirmButtonText: 'ok'
        })
        return;
      }
      
      console.log(this.resultadoForm.value);
      if(this.resultadoForm.invalid){
        swal.fire({
          title: 'Todos los campos deben ser llenados',
          text: "",
          type: 'warning',
          confirmButtonText: 'ok'
        })
        return;
      }
  
      if(this.resultadoForm.controls['estatus'].value != 2 && this.resultadoForm.controls['estatus'].value != 3){
        this.resultadoForm.controls['estatus'].setValue(1);
      }
    }
    
    this.loading = true;
    this.putResultado = this.partidoService.putResultado(this.resultadoForm.value);
    
    this.putResultado
      .pipe(first())
      .subscribe(
        response => {
          swal.fire({
            title: 'Correcto!',
            text: response.message,
            type: 'success',
            confirmButtonText: 'ok'
          }),
          $("#resultadoModal").modal("hide");
          this.resultadoForm.reset();
          this.submitted = false;
          this.loading = false;
          // this.buscarPartidos();
          this.getPartidosManual();
        },
        error => {
          // pending to show error
          this.loading = false;
          console.error(error);
        }
    )
  }

  // Eliminar partido
  public deletePartido(partido: Partido){
    swal.fire({
      title: "Seguro que deseas suspender el partido?",
      text: "", 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí. Suspender!'
    }).then((result) => {
      if(result.value){
        this.loading = true;
        this.partidoService.suspenderPartido(partido.id)
          .pipe(first())
          .subscribe(
            response => {
              swal.fire({
                title: 'Correcto!',
                text: response.message,
                type: 'success',
                confirmButtonText: 'ok'
              })
              this.loading = false;
              this.getPartidosManual()
            },
            error => {
              console.error(error);
              this.getPartidosManual()
            }
          )
      }
    });
  
  }

  // Código agregado para generar partidos manualmente

  getJornadas(){
    this.jornadas = 32;
    for(var i=1; i<=this.jornadas;i++){
      this.jornadasA.push(i);
    }
  }

  getEquiposXCategoria(){
    this.loading = true;
    // this.flag = false;
    this.equipos = [];
    this.equipoService.getEquipoCategoria(this.categoriaSeleccionada).subscribe(
        res => {
          this.equipos = res;
        },
        err => console.error(err)
      );
  }

  getEquiposCategoria() {
    // this.loading = true;
    // this.flag = false;
    this.equipos = [];
    this.equipoService.getEquiposIds(this.categoriaSeleccionada).subscribe(
        res => {
          this.equipos = res;
          this.loading = false;
          // var equipos2 = res;
          // var equipos = [];

          // console.log("GET EQUIPOS CATEGORIA")
          // console.log(this.categoriaSeleccionada)
          // for(var equipo of equipos2){
          //   if(equipo.id_categoria == this.categoriaSeleccionada){
          //     equipos.push(equipo);
          //   }
          // }

          // var min: Equipo;
          // var k = 0;
          // for(var i=0; i<equipos.length;i++){
          //   for(var l = 0; l < equipos.length; l++){
          //     if(equipos[l].estatus != -1){
          //       min = equipos[l];
          //       k = l;
          //     }
          //   }
          //   for(var j=0; j<equipos.length;j++){
          //     if(min.nombre_equipo > equipos[j].nombre_equipo && equipos[j].estatus != -1){
          //       min = equipos[j];
          //       k = j;
          //     }
          //   }
          //   this.equipos.push(min);
          //   equipos[k].estatus = -1;
          //}
          //this.loading = false;
          // this.flag = true;

          // console.log("FIN GET EQUIPOS CATEGORIA")
          //console.log(this.equipos)
        },
        err => console.error(err)
      );
  }

  onSubmitManual(){
    let date = new Date();
    date.setFullYear(this.partidoManualForm.value['fecha'].substring(0,4));
    date.setMonth(+(this.partidoManualForm.value['fecha'].substring(5,7))-1);
    // date.setMonth(this.partidoManualForm.value['fecha'].substring(5,7));
    date.setDate(this.partidoManualForm.value['fecha'].substring(8,10));
    console.log(this.partidoManualForm.value['hora'].substring(0,2)-5);
    date.setHours(+(this.partidoManualForm.value['hora'].substring(0,2))-6);
    date.setMinutes(this.partidoManualForm.value['hora'].substring(3,5));
    date.setSeconds(0);
    console.log(date)
    
    let x = this.partidoManualForm.value['jornada'];
    this.partidoManualForm.controls['jornada'].setValue(+x);
    this.partidoManualForm.controls['horario_partido'].setValue(date);
    console.log(this.partidoManualForm.value);

    this.submitted = true;

    if(this.partidoManualForm.invalid){
      swal.fire({
        title: 'Todos los campos deben ser llenados',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }

    // this.partidoForm.controls['id_torneo'].setValue(this.partidoForm.value.id_torneo.id);
    // this.partidoForm.controls['id_equipo_local'].setValue(this.partidoForm.value.id_equipo_local.id);
    // this.partidoForm.controls['id_equipo_visita'].setValue(this.partidoForm.value.id_equipo_visita.id);

    this.loading = true;
    if (this.modalTitleText === 'Registrar partido'){
      this.partidoManualForm.controls['id'].setValue(null);
      this.postOrPutPartido = this.partidoService.postPartido(this.partidoManualForm.value);
    }
    else{
      this.postOrPutPartido = this.partidoService.putPartido(this.partidoManualForm.value);
      this.getPartidosManual();
    }
    
    this.postOrPutPartido
      .pipe(first())
      .subscribe(
        response => {
          swal.fire({
            title: 'Correcto!',
            text: response.message,
            type: 'success',
            confirmButtonText: 'ok'
          }),
          
          this.submitted = false;
          this.loading = false;
          // if(this.modalTitleText !== 'Registrar partido'){
          //   // this.getPartidosManual();
          //   // $("#partidoModal").modal("hide");
          //   // this.partidoForm.reset();
          // }
         
        },
        error => {
          // pending to show error
          console.error(error);
        }
    )
   }

  getPartidosManual(){
    this.partidos = [];
    console.log("Partidos del torneo "+this.torneoSeleccionado);
    console.log("Partidos de la jornada "+this.jornadaSeleccionada);
    this.loading = true;
    this.partidoService.getConsultaPartidosFiltradosJornada(this.torneoSeleccionado,this.categoriaSeleccionada,this.jornadaSeleccionada)
      .pipe(first())
      .subscribe(partidos => {
        console.log(partidos);
        var partidos: Partido[];
        this.partidos = partidos;
        
        // for(var partido of partidos){
        //   if(partido.id_torneo == this.torneoSeleccionado && partido.jornada == this.jornadaSeleccionada && partido.estatus == 1){
        //     if(partido.id_cancha==1){
        //       partido.cancha.nombre_cancha='A. T.'
        //     }
        //     if(partido.id_cancha==2){
        //       partido.cancha.nombre_cancha='G. T.'
        //     }
        //     if(partido.id_cancha==3){
        //       partido.cancha.nombre_cancha='S. P.'
        //     }
        //     if(partido.id_cancha==4){
        //       partido.cancha.nombre_cancha='V. G. F.'
        //     }
        //     this.partidos.push(partido);
        //   }
        // }


        this.loading = false;
      },
        error => {
          console.error(error);
        }
    );
  }

  public actualizarPartido(partido: Partido){
    this.getEquiposCategoria();
    this.modalTitleText = 'Editar Partido: ' + partido.equipo_local.nombre_equipo + ' vs ' + partido.equipo_visita.nombre_equipo;

    this.partidoManualForm.controls['id'].setValue(partido.id);
    this.partidoManualForm.controls['id_torneo'].setValue(partido.id_torneo);
    this.partidoManualForm.controls['id_categoria'].setValue(this.categoriaSeleccionada);
    this.partidoManualForm.controls['id_cancha'].setValue(partido.id_cancha);
    this.partidoManualForm.controls['id_equipo_local'].setValue(partido.id_equipo_local);
    this.partidoManualForm.controls['id_equipo_visita'].setValue(partido.id_equipo_visita);
    this.partidoManualForm.controls['jornada'].setValue(partido.jornada);
    this.partidoManualForm.controls['horario_partido'].setValue(partido.horario_partido);
    this.partidoManualForm.controls['fecha'].setValue(this.partidoManualForm.value['horario_partido'].substring(0,10));
    this.partidoManualForm.controls['hora'].setValue(this.partidoManualForm.value['horario_partido'].substring(11,22));

    // console.log(this.partidoManualForm.value['horario_partido'])
    
    // console.log(this.partidoManualForm.value['horario_partido'].substring(0,10))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(11,22))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(11,19))

    // console.log(this.partidoManualForm.value['horario_partido'].substring(0,9))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(0,11))
    
    // console.log(this.partidoManualForm.value['horario_partido'].substring(9,20))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(10,20))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(11,20))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(9,21))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(10,21))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(11,21))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(9,22))
    // console.log(this.partidoManualForm.value['horario_partido'].substring(10,22))

    this.partidoManualForm.controls['goles_local'].setValue(partido.goles_local);
    this.partidoManualForm.controls['goles_visita'].setValue(partido.goles_visita);
    this.partidoManualForm.controls['estado'].setValue(partido.estado);
    this.partidoManualForm.controls['estatus'].setValue(partido.estatus);


    $("#partidoModal").modal("show");
  }

 }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { MainComponent } from './components/main/main.component';
import { EquipoComponent } from './components/equipo/equipo.component';
import { EquipoDetalleComponent } from './components/equipo-detalle/equipo-detalle.component';
import { JugadorComponent } from './components/jugador/jugador.component';
import { TorneoComponent } from './components/torneo/torneo.component';
import { HorarioComponent } from './components/horario/horario.component';
import { CategoriaComponent } from './components/categoria/categoria.component';
import { CastigoComponent } from './components/castigo/castigo.component';
import { ReporteComponent } from './components/reporte/reporte.component';
import { TablaPosicionComponent } from './components/tabla-posicion/tabla-posicion.component';
import { PartidoComponent } from './components/partido/partido.component';
import { registerLocaleData } from '@angular/common';
import { ReglamentoComponent } from './reglamento/reglamento.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NumberDirective } from './_helpers/number-only';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {NgxPhotoEditorModule} from "ngx-photo-editor";

import es from '@angular/common/locales/es';
import { AvisoComponent } from './components/aviso/aviso.component';
import { JugadorDetalleComponent } from './components/jugador-detalle/jugador-detalle.component';
import { EquipoRegistroComponent } from './components/equipo-registro/equipo-registro.component';
import { RolJuegosComponent } from './components/rol-juegos/rol-juegos.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TorneoGrupoComponent } from './components/torneo-grupo/torneo-grupo.component';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    UsuarioComponent,
    MainComponent,
    EquipoComponent,
    EquipoDetalleComponent,
    JugadorComponent,
    CategoriaComponent,
    CastigoComponent,
    ReporteComponent,
    TorneoComponent,
    HorarioComponent,
    CategoriaComponent,
    TablaPosicionComponent,
    PartidoComponent,
    ReglamentoComponent,
    NumberDirective,
    AvisoComponent,
    JugadorDetalleComponent,
    EquipoRegistroComponent,
    RolJuegosComponent,
    TorneoGrupoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FormsModule,
    BrowserAnimationsModule,
    Ng2SearchPipeModule,
    NgxPhotoEditorModule
  ],
  providers: [
              { provide: LOCALE_ID, useValue: 'es-*'},
              { provide: HTTP_INTERCEPTORS,  useClass: TokenInterceptor,  multi: true },
              { provide: LocationStrategy, useClass: HashLocationStrategy }
            ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }

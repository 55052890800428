import { Component, OnInit } from '@angular/core';

import { Jugador } from '../../_models/jugador';
import { JugadorService } from '../../_services/jugador.service';
import { JugadorEquipoService } from '../../_services/jugador-equipo.service';
import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';

import {CroppedEvent} from 'ngx-photo-editor';
import { JugadorFecha } from 'src/app/_models/jugador_fecha';

declare var $ :any;

@Component({
  selector: 'app-jugador-detalle',
  templateUrl: './jugador-detalle.component.html',
  styleUrls: ['./jugador-detalle.component.css']
})
export class JugadorDetalleComponent implements OnInit {

  jugador: Jugador;
  id_jugador: any;
  id_equipo: any;
  edad: number;
  loading = true; 

  // Actualizar Jugador
  dob = '00';  // Día de nacimiento
  mob = '00';  // Mes de nacimiento
  yob = '0000';  // Año de nacimiento
  identificacion: File;           // File de identificación del jugador
  identificacion_name: string;
  foto: File;                     // File de fotografía del jugador
  foto_name: string;
  boton: string;                  // Nombre del botón html: Registrar o Actualizar Jugador
  actualizar = false;             // Identificar cuando se debe Registrar o Actualizar un Jugador
  modalTitleText: string;         // Nombre de la ventana para Registrar o Actualizar Jugador
  loading_create = false;

  imageChangedEvent: any;
  base64: any;
  imageChangedEventIfe: any;
  base64Ife: any;

  jugadorFecha: JugadorFecha = new JugadorFecha();

  bDomicilio = false;

  constructor(
    private jugadorService: JugadorService, 
    private jugadorEquipoService: JugadorEquipoService, 
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.id_equipo = +localStorage.getItem('id_equipo');
    console.log(this.id_equipo);
    this.id_jugador = this.route.snapshot.paramMap.get('id');
    console.log(this.id_jugador);
    this.getJugador(this.id_jugador);
  }

  getJugador(id: string){
    this.loading = true;
      this.jugador = null;
      this.jugadorEquipoService.getJugador(this.id_equipo, id).subscribe(
          res => {
            this.jugador = res;
            this.getJugadorBitacora(this.jugador.id);
            this.loading = false;
            console.log(this.jugador)

            this.getEdad(+this.jugador.fecha_nacimiento.toString().substring(0,4), +this.jugador.fecha_nacimiento.toString().substring(5,7), +this.jugador.fecha_nacimiento.toString().substring(8,10), );
          },
          err => console.error(err)
        );
  }

  getJugadorBitacora(id: number){
    this.jugadorFecha = new JugadorFecha();
    this.jugadorService.getJugadorBitacora(id).subscribe(
        res => {
          this.jugadorFecha = res;
          this.loading = false;
          console.log(this.jugadorFecha)
        },
        err => console.error(err)
      );
  }

  getEdad(y, m, d){
    m = m-1;
    var today = new Date();

    var diff_y = today.getUTCFullYear() - y;
    this.edad = diff_y;

    var diff_m = today.getMonth() - m;
    if(diff_m < 0){
      this.edad = this.edad - 1;
    }

    var diff_d = today.getDate() - d;
    if(diff_m == 0){
      if(diff_d < 0){
        this.edad = this.edad - 1;
      }
    }
  }

  mostrarDomicilio(){
    this.bDomicilio = !this.bDomicilio;
  }

  mostrarIdentificacion(identificacion: string){
    Swal.fire({
      imageUrl: identificacion,
      width: '640px',
      imageHeight: 340,
    })
  }

  regresarAJugadores(id, id_categoria){
    this.router.navigate(['jugador/'+id+'/'+id_categoria]);
  }

  actualizarJugador(jugador: Jugador) {
    this.jugador = jugador;

    console.log(this.jugador);
    console.log(this.id_equipo);
    if(this.id_equipo == 0)
      this.id_equipo = this.jugador.jugador_equipo[0].id_categoria;
    console.log(this.id_equipo);

    this.yob = this.jugador.fecha_nacimiento.toString().substring(0,4);
    this.mob = this.jugador.fecha_nacimiento.toString().substring(5,7);
    this.dob = this.jugador.fecha_nacimiento.toString().substring(8,20);

    this.identificacion_name = 'Cambiar identificación...';
    this.foto_name = 'Cambiar fotografía...';
    this.actualizar = true;
    this.boton = 'Actualizar Jugador'
    this.modalTitleText = 'Editar Jugador: ' + jugador.nombre_jugador;
    this.loading_create = false; 
    $("#jugadorModal").modal("show");
  }

  updateJugador() {
    this.loading_create = true; 
    if(this.validarJugador(this.jugador)){
      this.jugadorService.updateJugador(this.jugador.id, this.jugador, this.id_equipo).subscribe(
          res => {
            Swal.fire(
              'Actualizado!',
              'El jugador ' + this.jugador.nombre_jugador + ' ha sido actualizado',
              'success'
            )
            $("#jugadorModal").modal("hide");
            this.getJugador(this.id_jugador);
            this.loading_create = false; 
          },
          err => console.error(err)
        );
    }
  }

  // Validar campos del jugador a Registrar o Actualizar
  validarJugador(jugador: Jugador): boolean{
    // var fecha_nacimiento_min = new Date("2005-01-01");
    // var fecha_nacimiento_max = new Date("1955-01-01");

    //VALIDAR FECHA
    if(this.dob == '30' || this.dob == '31'){
      if(this.mob == '02'){
        this.dob = '29';
      }
    }
    if(this.dob == '31'){
      if(this.mob == '04' || this.mob == '06' || this.mob == '09' || this.mob == '11'){
        this.dob = '30';
      }
    }
    this.jugador.fecha_nacimiento = new Date(this.yob+"-"+this.mob+"-"+this.dob);
    
    if (this.tiene_caracteres(jugador.nombre_jugador) || this.tiene_caracteres(jugador.apellidos_jugador)) {
      Swal.fire({
        type: 'error',
        title: 'Error!',
        text: 'El nombre y apellidos solo pueden contener letras!'
      })
      return false;
    }else if(jugador.nombre_jugador=='' || jugador.apellidos_jugador=='' || jugador.domicilio==''  || jugador.identificacion==''  || jugador.foto=='' ){
      Swal.fire({
        type: 'error',
        title: 'Error!',
        text: 'Favor de completar todos los campos!'
      })
    // }else if(fecha_nacimiento_jugador > fecha_nacimiento_min || fecha_nacimiento_jugador < fecha_nacimiento_max){
    //   Swal.fire({
    //     type: 'error',
    //     title: 'Error!',
    //     text: 'El jugador debe tener entre 15 y 65 años de edad!'
    //   })
    //   return false;
    }else{
      return true;
    }
  }

  // Valida si un string contiene números (validar nombre y apellidos del jugador)
  tiene_caracteres(texto: string): boolean{
    var caracteres="0123456789!#$%&/()=?¿¡,.;:{}[]+-";
    for(var i=0; i<texto.length; i++){
       if (caracteres.indexOf(texto.charAt(i),0)!=-1){
          return true;
       }
    }
    return false;
  }

  // Al cargar la identificación en el html, la convierte a base64 y asigna a jugador.identificacion
  identificacionSelected(event){
    this.identificacion = <File> event.target.files[0];
    this.identificacion_name = this.identificacion.name;
    this.jugador.identificacion = this.convertIdentificacion(this.identificacion,this.jugador);
  }

  // Al cargar la fotografía en el html, la convierte a base64 y asigna a jugador.identificacion
  fotoSelected(event){
    this.foto = <File> event.target.files[0];
    this.foto_name = this.foto.name;
    this.jugador.foto = this.convertFoto(this.foto, this.jugador);
  }

  // Convierte imagen de identificación a base64
  convertIdentificacion(file,jugador: Jugador): any {
    let reader = new FileReader();
    let me: any;
    reader.readAsDataURL(file);
    reader.onload = function () {
      me = reader.result;
      jugador.identificacion = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  // Convierte imagen de fotografía a base64
  convertFoto(file,jugador: Jugador): any {
    let reader = new FileReader();
    let me: any;
    reader.readAsDataURL(file);
    reader.onload = function () {
      me = reader.result;
      jugador.foto = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: CroppedEvent) {
    this.base64 = event.base64;
    this.jugador.foto = this.base64; 
    this.foto_name = "Fotografía Seleccionada!";
  }

  fileChangeEventIfe(event: any) {
    this.imageChangedEventIfe = event;
  }

  imageCroppedIfe(event: CroppedEvent) {
    this.base64Ife = event.base64;
    this.jugador.identificacion = this.base64Ife; 
    this.identificacion_name = "Identificación Seleccionada!";
  }

}

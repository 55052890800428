import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, NgForm } from '@angular/forms';
import {AuthService} from '../../_services/auth.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2'; 
import { AuthGuard } from '../login/auth.guard';
declare var $ :any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'] 
})
export class RegisterComponent implements OnInit {
  // Atributos de clase
  usuarios = [];
  userForm: FormGroup;
  loading = false;
  submitted = false;
  modalTitleText: String;
  postOrPutUser;

  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService, private authGuard: AuthGuard) { }

  // Funcion que se ejecuta al cargar el componente de Usuario
  ngOnInit() {
    if(!this.puedeVer()){
        swal.fire({
          title: 'No Autorizado',
          text: "No estas autorizado para observar este contenido. Identificate por favor.",
          type: 'error',
          confirmButtonText: 'Cerrar'
        });
        this.router.navigate(['login']);
    }
    this.userForm = this.formBuilder.group({
      id: [''],
      id_tipo_usuario: ['', Validators.required],
      username: ['', Validators.required],
      nombre_usuario: ['', Validators.required],
      apellidos_usuario: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
      domicilio: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      telefono_1: ['', Validators.required],
      telefono_2: [''],
      estatus: true
    })
  }

  puedeVer(){    
    return this.authGuard.renderOpcional();
  }

  

  // getter para acceder a los campos del formulario
  get f() { return this.userForm.controls; }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.userForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
}
  
  onSubmit(form){
    this.submitted = true;


    if(this.userForm.invalid){
      let campos_invalidos = this.findInvalidControls();

      if(campos_invalidos.includes("correo")){
        swal.fire({
          title: 'La dirección de correo debe ser válida.',
          text: "",
          type: 'warning',
          confirmButtonText: 'ok'
        });
        return;
      }

      swal.fire({
        title: 'Todos los campos obligatorios deben ser llenados.',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      });
      return;
    }

    this.loading = true;
    this.authService.register(form.value)
      .subscribe(
        res => 
        {
        this.userForm.reset();
        this.submitted = false;
        this.loading = false;   
        swal.fire({
          title: 'Usuario Registrado Correctamente',
          text: "El usuario se ha registrado correctamente",
          type: 'success',
          confirmButtonText: 'ok'
        });   
        console.log(res);  
        },
        error =>  
        {
          console.log(error);
          this.userForm.reset;
          this.loading = false;
      }
    )



  }


  

}

import { Component, ViewChild, ElementRef } from '@angular/core';
import {AuthGuard} from '../app/components/login/auth.guard';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Security } from './_helpers/super-seguridad';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Unificación Deportiva';
  
  isAdministrador: boolean;
  isLogged: boolean;

  username: String;

  @ViewChild('navbarToggler', {static: false}) navbarToggler:ElementRef;

  constructor(
    private authGuard: AuthGuard,
     private router: Router,
     private security: Security) {}

   ngDoCheck(){
    this.isAdministrador = this.security.puedeVer();
    this.isLogged = this.authGuard.renderOpcional();

    if(this.isLogged){
      this.username = localStorage.getItem('username').substring(0, localStorage.getItem('username').indexOf('@'));
    }
  }
  
  cerrarSesion(){
    swal.fire({
      title: "Cerrar Sesión",
      text: "Estás seguro que deseas salir de tu sesión?", 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, salir'
    }).then((result) => {
      if(result.value){
        this.router.navigate(['']);
        this.isLogged = false;
        return this.authGuard.logout();
      }
    });
   
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }

}

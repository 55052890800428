import { Component, OnInit, SystemJsNgModuleLoader } from '@angular/core';

import { PartidoService } from '../../_services/partido.service';
import { CategoriaService } from 'src/app/_services/categoria.service';
import { TorneoService } from 'src/app/_services/torneo.service';
import { EquipoService } from 'src/app/_services/equipo.service';

import { TablaPosicion } from '../../_models/tabla-posicion';

import { first } from 'rxjs/operators';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2';

declare var $ :any;

@Component({
  selector: 'app-tabla-posicion',
  templateUrl: './tabla-posicion.component.html',
  styleUrls: ['./tabla-posicion.component.css']
})

export class TablaPosicionComponent implements OnInit {

  // Atributos de clase
  tabla_posiciones: TablaPosicion[] = [];
  tabla_posiciones1: TablaPosicion[] = [];
  tabla_posiciones2: TablaPosicion[] = [];
  partidos = [];
  torneos = [];
  categorias: any;

  tb = false;
  tb1 = false;
  tb2 = false;

  torneoSeleccionado: number;
  tipoTorneoSeleccionado: number;
  categoriaSeleccionada: number;
  estadoSeleccionado: string;

  loading = false;
  loadingCategoria = false;
  loadingTorneo = false;

  // constructor(private partidoService: PartidoService) { }
  constructor(
      private partidoService: PartidoService,
      private categoriaService: CategoriaService,
      private torneoService: TorneoService,
      private equipoService: EquipoService,
    ) {

    // Get Categorías
    this.categorias = this.getCategorias();
   }

  ngOnInit() {
  }

    /*
    Funciones CRUDL
  */

  getCategorias() {
    this.loadingCategoria = true;
    this.categoriaService.getCategoriasTabla().subscribe(
        res => {
          this.categorias = res;
          this.loadingCategoria = false;
        },
        err => console.error(err)
      );
  }

  getTorneos() {
    this.loadingTorneo = true;
    this.torneos = [];
    this.tabla_posiciones = [];
    this.torneoService.getTorneosTabla().subscribe(
        res => {
          var torneos = res;
          for(var torneo of torneos){
            if(torneo.id_categoria == this.categoriaSeleccionada){
              this.torneos.push(torneo);
            }
          }
          if(this.torneos.length == 0){
            Swal.fire({
              type: 'info',
              title: 'Aviso',
              text: 'No existen torneos en la categoría seleccionada'
            })
          }
          this.loadingTorneo = false;
        },
        err => console.error(err)
      );
      
  }

  getPrimerLugar(tabla_posiciones){
    console.log(tabla_posiciones[0].nombre_equipo);
    console.log(tabla_posiciones[0].pts);
    return tabla_posiciones[0];

  }
  getTablaPosiciones(){
    //Consulta primero si el torneo ya fue finalizado
    this.loading = true;
    this.tabla_posiciones = [];
    console.log(this.torneoSeleccionado);
    this.torneoService.getTorneoFinalizado(this.torneoSeleccionado)
    .pipe(first())
    .subscribe(res => {
        console.log(res);
        this.tabla_posiciones = res;
        if(this.tabla_posiciones.length > 0){
          if(this.getTipoTorneo(this.torneoSeleccionado) == 1){
            this.tabla_posiciones = this.ordenaTablaPosicionesLiga(this.tabla_posiciones);
            this.tb = true;
            this.tb1 = false;
            this.tb2 = false;
          }else{
            this.tabla_posiciones1 = this.ordenaTablaPosicionesCopa(this.tabla_posiciones,1);
            this.tabla_posiciones2 = this.ordenaTablaPosicionesCopa(this.tabla_posiciones,2);
            this.tb = false;
            this.tb1 = true;
            this.tb2 = true;
          }
          this.loading = false;
          console.log("TORNEO YA FUE FINALIZADO!");
        }else{
          console.log("TORNEO NO HA SIDO FINALIZADO!");

          this.loading = true;
          this.tabla_posiciones = [];
          console.log(this.torneoSeleccionado);
          this.partidoService.getTablaPosiciones(this.torneoSeleccionado)
          .pipe(first())
          .subscribe(res => {
              console.log(res);
              this.tabla_posiciones = res;
              if(this.getTipoTorneo(this.torneoSeleccionado) == 1){
                this.tabla_posiciones = this.ordenaTablaPosicionesLiga(this.tabla_posiciones);
                this.tb = true;
                this.tb1 = false;
                this.tb2 = false;
              }else{
                this.tabla_posiciones1 = this.ordenaTablaPosicionesCopa(this.tabla_posiciones,1);
                this.tabla_posiciones2 = this.ordenaTablaPosicionesCopa(this.tabla_posiciones,2);
                this.tb = false;
                this.tb1 = true;
                this.tb2 = true;
              }
              this.loading = false;
            },
              error => {
                console.error(error);
              }
          );
        }
      },
        error => {
          console.error(error);
        }
    );
  }

  ordenaTablaPosicionesLiga(tabla_posiciones){
    // Ordena posiciones por pts, dg, gf
    var posiciones: TablaPosicion[] = [];
    var max: TablaPosicion;
    var k = 0;

    for(var j = 0; j < tabla_posiciones.length; j++){
      for(var l = 0; l < tabla_posiciones.length; l++){
        if(tabla_posiciones[l].id_equipo != -1){
          max = tabla_posiciones[l];
          k = l;
        }
      }
      for(var i = 0; i < tabla_posiciones.length; i++){
        if(max.pts < tabla_posiciones[i].pts && tabla_posiciones[i].id_equipo != -1){
          max = tabla_posiciones[i];
          k = i;
        }else 
        if(max.pts == tabla_posiciones[i].pts && tabla_posiciones[i].id_equipo != -1){
          if(max.gd < tabla_posiciones[i].gd){
            max = tabla_posiciones[i];
            k = i;
          }else
          if(max.gd == tabla_posiciones[i].gd){
            if(max.gf < tabla_posiciones[i].gf){
              max = tabla_posiciones[i];
              k = i;
            }
          }
        }
      }
      posiciones.push(max);
      tabla_posiciones[k].id_equipo = -1;
    }

    tabla_posiciones = posiciones;

    for(var i = 0; i < tabla_posiciones.length; i++){
      tabla_posiciones[i].id_equipo = i+1;
    }
      this.getPrimerLugar(tabla_posiciones);
    return posiciones;
  }

  ordenaTablaPosicionesCopa(tabla_posiciones, grupo){

    // Equipos del Grupo
    var posicionesG: TablaPosicion[] = [];
    for(var i = 0; i < tabla_posiciones.length; i++){
      if(tabla_posiciones[i].grupo == grupo){
        posicionesG.push(tabla_posiciones[i]);
      }
    }

    tabla_posiciones = posicionesG;

    
    if(tabla_posiciones.length == 0){
      return [];
    }

    // Ordena posiciones por pts, dg, gf
    var posiciones: TablaPosicion[] = [];
    var max: TablaPosicion;
    var k = 0;

    for(var j = 0; j < tabla_posiciones.length; j++){
      for(var l = 0; l < tabla_posiciones.length; l++){
        if(tabla_posiciones[l].id_equipo != -1){
          max = tabla_posiciones[l];
          k = l;
        }
      }
      for(var i = 0; i < tabla_posiciones.length; i++){
        if(max.pts < tabla_posiciones[i].pts && tabla_posiciones[i].id_equipo != -1){
          max = tabla_posiciones[i];
          k = i;
        }else 
        if(max.pts == tabla_posiciones[i].pts && tabla_posiciones[i].id_equipo != -1){
          if(max.gd < tabla_posiciones[i].gd){
            max = tabla_posiciones[i];
            k = i;
          }else
          if(max.gd == tabla_posiciones[i].gd){
            if(max.gf < tabla_posiciones[i].gf){
              max = tabla_posiciones[i];
              k = i;
            }
          }
        }
      }
      posiciones.push(max);
      tabla_posiciones[k].id_equipo = -1;
    }

    tabla_posiciones = posiciones;

    for(var i = 0; i < tabla_posiciones.length; i++){
      tabla_posiciones[i].id_equipo = i+1;
    }
      this.getPrimerLugar(tabla_posiciones);
    return posiciones;
  }

  getTipoTorneo(id){
    for(var torneo of this.torneos){
      if(torneo.id == id){
        return torneo.id_tipo_torneo;
      }
    }
  }

  fecha_actual(){
    
    let fecha_actual = new Date();
    let mes_letra: string;
  
    let mes_numero = (fecha_actual.getMonth()+1);
  
    switch(mes_numero){
      case 1:
        mes_letra = "Enero";
        break;
      case 2:
        mes_letra = "Febrero";
        break;
      case 3:
        mes_letra = "Marzo";
        break;
      case 4:
        mes_letra = "Abril";
        break;
      case 5:
        mes_letra = "Mayo";
        break;
      case 6:
        mes_letra = "Junio";
        break;
      case 7:
        mes_letra = "Julio";
        break;
      case 8:
        mes_letra = "Agosto";
        break;
      case 9:
        mes_letra = "Septiembre";
        break;
      case 10:
        mes_letra = "Octubre";
        break;
      case 11:
        mes_letra = "Noviembre";
        break;
      case 12:
        mes_letra = "Diciembre";
        break; 
      default:
          mes_letra = "n/a";
    }
  
    let fecha = fecha_actual.getDate() + '-' +  mes_letra + '-' + fecha_actual.getFullYear();
    let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();
    
    return fecha + ' ' + hora; 
  }

  /*
  getEquipos() {
    this.loading = true;
    this.tabla_posiciones = [];
    this.tabla_posiciones0 = [];
    this.tabla_posiciones1 = [];
    this.tb = false;
    this.tb1 = false;
    this.tb2 = false;
    for(var torneo of this.torneos){
      if(torneo.id == this.torneoSeleccionado){
        this.tipoTorneoSeleccionado = torneo.id_tipo_torneo;
      }
    }
    this.equipoService.getAllTabla().subscribe(
        res => {
          var equipos = res;
          for(var equipo of equipos){
            if(equipo.id_categoria == this.categoriaSeleccionada){
              if(this.tipoTorneoSeleccionado == 1){
                this.tabla_posiciones.push(new TablaPosicion(equipo.id,equipo.logo,equipo.nombre_equipo,equipo.color_playera,equipo.color_short,0,0,0,0,0,0,0,0,0));
              }
              if(this.tipoTorneoSeleccionado == 2){
                if(equipo.grupo == 0){
                  this.tabla_posiciones0.push(new TablaPosicion(equipo.id,equipo.logo,equipo.nombre_equipo,equipo.color_playera,equipo.color_short,0,0,0,0,0,0,0,0,0));
                }else{
                  this.tabla_posiciones1.push(new TablaPosicion(equipo.id,equipo.logo,equipo.nombre_equipo,equipo.color_playera,equipo.color_short,0,0,0,0,0,0,0,0,0));
                }
              }
            }
          }
          this.getPartidosFiltrados();
        },
        err => console.error(err)
      );
  }

  getPartidosFiltrados(){
    this.tb = false;
    this.tb1 = false;
    this.tb2 = false;
    this.estadoSeleccionado = "DISPUTADO";
    this.loading = true;

    this.partidoService.getTablaPartidosFiltrados(this.torneoSeleccionado, this.categoriaSeleccionada, this.estadoSeleccionado)
    .pipe(first())
    .subscribe(partidos => {
        this.partidos = partidos
        this.loading = false;

        if(this.tipoTorneoSeleccionado == 1){
          this.llenarTablaPosiciones(this.tabla_posiciones);
          this.tabla_posiciones = this.ordenaTablaPosiciones(this.tabla_posiciones);
          this.tb = true;
        }
        if(this.tipoTorneoSeleccionado == 2){
          this.llenarTablaPosiciones(this.tabla_posiciones0);
          this.llenarTablaPosiciones(this.tabla_posiciones1);
          this.tabla_posiciones0 = this.ordenaTablaPosiciones(this.tabla_posiciones0);
          this.tabla_posiciones1 = this.ordenaTablaPosiciones(this.tabla_posiciones1);
          this.tb1 = true;
          this.tb2 = true;
        }
      },
        error => {
          console.error(error);
        }
    );
  }

  llenarTablaPosiciones(tabla_posiciones){
    for(var partido of this.partidos){
      // GANO LOCAL
      if(partido.goles_local > partido.goles_visita){
        for(var i = 0; i < tabla_posiciones.length; i++){
          if(tabla_posiciones[i].id_equipo == partido.id_equipo_local){
            tabla_posiciones[i].pj++;
            tabla_posiciones[i].pg++;
            tabla_posiciones[i].gf+=partido.goles_local;
            tabla_posiciones[i].gc+=partido.goles_visita;
            tabla_posiciones[i].dg+=partido.goles_local;
            tabla_posiciones[i].dg-=partido.goles_visita;
            tabla_posiciones[i].pts+=3;
          }
          if(tabla_posiciones[i].id_equipo == partido.id_equipo_visita){
            tabla_posiciones[i].pj++;
            tabla_posiciones[i].pp++;
            tabla_posiciones[i].gf+=partido.goles_visita;
            tabla_posiciones[i].gc+=partido.goles_local;
            tabla_posiciones[i].dg+=partido.goles_visita;
            tabla_posiciones[i].dg-=partido.goles_local;
          }
        }
      }

      // GANO VISITA
      if(partido.goles_local < partido.goles_visita){
        for(var i = 0; i < tabla_posiciones.length; i++){
          if(tabla_posiciones[i].id_equipo == partido.id_equipo_visita){
            tabla_posiciones[i].pj++;
            tabla_posiciones[i].pg++;
            tabla_posiciones[i].gf+=partido.goles_visita;
            tabla_posiciones[i].gc+=partido.goles_local;
            tabla_posiciones[i].dg+=partido.goles_visita;
            tabla_posiciones[i].dg-=partido.goles_local;
            tabla_posiciones[i].pts+=3;
          }
          if(tabla_posiciones[i].id_equipo == partido.id_equipo_local){
            tabla_posiciones[i].pj++;
            tabla_posiciones[i].pp++;
            tabla_posiciones[i].gf+=partido.goles_local;
            tabla_posiciones[i].gc+=partido.goles_visita;
            tabla_posiciones[i].dg+=partido.goles_local;
            tabla_posiciones[i].dg-=partido.goles_visita;
          }
        }
      }

      // EMPATE
      if(partido.goles_local == partido.goles_visita){
        for(var i = 0; i < tabla_posiciones.length; i++){
          if(tabla_posiciones[i].id_equipo == partido.id_equipo_local){
            tabla_posiciones[i].pj++;
            tabla_posiciones[i].pe++;
            tabla_posiciones[i].gf+=partido.goles_local;
            tabla_posiciones[i].gc+=partido.goles_visita;
            tabla_posiciones[i].dg+=partido.goles_local;
            tabla_posiciones[i].dg-=partido.goles_visita;

            console.log("Local" + partido.estatus);
            if(partido.estatus == 2){
              tabla_posiciones[i].pts+=2;
            }else{
              tabla_posiciones[i].pts+=1;
            }
            
          }
          if(tabla_posiciones[i].id_equipo == partido.id_equipo_visita){
            tabla_posiciones[i].pj++;
            tabla_posiciones[i].pe++;
            tabla_posiciones[i].gf+=partido.goles_visita;
            tabla_posiciones[i].gc+=partido.goles_local;
            tabla_posiciones[i].dg+=partido.goles_visita;
            tabla_posiciones[i].dg-=partido.goles_local;

            console.log("Visitante" + partido.estatus);
            if(partido.estatus == 3){
              tabla_posiciones[i].pts+=2;
            }else{
              tabla_posiciones[i].pts+=1;
            }
          }
        }
      }
    }
  }
*/
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TipoTorneo } from '../_models/tipo_torneo';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})

export class TipoTorneoService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }


  getPartidoTipoTorneos() {
    return this.http.get<TipoTorneo[]>(this.baseUrl + 'consulta_partidos/tipoTorneo/');
  }

  getTipoTorneos() {
    return this.http.get<TipoTorneo[]>(this.baseUrl + 'tipoTorneo/');
  }
}

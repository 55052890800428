import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Partido } from '../_models';
import { Torneo } from '../_models/torneo';
import{ GlobalConstants } from '../common/global-constants';
import { TablaPosicion } from '../_models/tabla-posicion';
 
@Injectable({
  providedIn: 'root'
})
export class PartidoService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { } 

  suspenderPartido(id: number) {
     return this.http.delete<any>(this.baseUrl + 'partido/' + id);
  }

  getAll() {
    return this.http.get<Partido[]>(this.baseUrl + 'partido/');
  }

  getPartidosTorneo(torneo: number, categoria: number) {
    return this.http.get<Partido[]>(this.baseUrl + `partido/torneo/${torneo}/categoria/${categoria}` );
  }

  getTablaPartidosFiltrados(torneo: number, categoria: number, estado: String){
    return this.http.get<Partido[]>(this.baseUrl + `tabla_posiciones/partido/torneo/${torneo}/categoria/${categoria}/estado/${estado}` );
  }

  
  getConsultaPartidosFiltrados(torneo: number, categoria: number, estado: String) {
    return this.http.get<Partido[]>(this.baseUrl + `consulta_partidos/torneo/${torneo}/categoria/${categoria}/estado/${estado}` );
  }

  getConsultaPartidosFiltradosJornada(torneo: number, categoria: number, jornada: number){
    return this.http.get<Partido[]>(this.baseUrl + `consulta_partidos/torneo/${torneo}/categoria/${categoria}/jornada/${jornada}` );
  }

  getPartidosFiltrados(torneo: number, categoria: number, estado: String) {
    return this.http.get<Partido[]>(this.baseUrl + `partido/torneo/${torneo}/categoria/${categoria}/estado/${estado}` );
  }

  postPartido(partido: Partido){
    console.log(partido);
    return this.http.post<any>(this.baseUrl + 'partido/', partido);
  }

  putPartido(partido: Partido){
    return this.http.put<any>(this.baseUrl + 'partido/' + partido.id, partido);
  }

  deletePartido(id: number) {
    return this.http.delete<any>(this.baseUrl + 'partido/' + id);
  }

  putResultado(partido: Partido): any {
    return this.http.put<any>(this.baseUrl + 'partido/setResultado/' + partido.id, partido);
  }

  getTablaPosiciones(torneo: number){
    return this.http.get<TablaPosicion[]>(this.baseUrl + `tabla_posiciones/${torneo}` );
  }
  
}

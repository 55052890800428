import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cancha } from '../_models/cancha';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CanchaService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getCanchas() {
    return this.http.get<Cancha[]>(this.baseUrl + 'cancha/');
  }

  getPartidoCanchas() {
    return this.http.get<Cancha[]>(this.baseUrl + 'consulta_partidos/cancha');
  }
}

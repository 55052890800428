export class Usuario {
    id: number;
    id_tipo_usuario: number;
    username: string;
    nombre_usuario: string;
    apellidos_usuario: string;
    password: string;
    domicilio: string;
    correo: string;
    telefono_1: string;
    telefono_2: string;
    estatus: number;

    constructor(){
        this.id = 0;
        this.id_tipo_usuario = 0;
        this.username = "";
        this.nombre_usuario = "";
        this.apellidos_usuario = "";
        this.password = "";
        this.domicilio = "";
        this.correo = "";
        this.telefono_1 = "";
        this.telefono_2 = "";
        this.estatus = 0;
    }
}

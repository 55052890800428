import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Reporte } from '../_models/reporte';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getReportes() {
    return this.http.get<Reporte[]>(this.baseUrl + 'reporte/');
  }

  getReporte(id: string) {
    return this.http.get<Reporte>(this.baseUrl + 'reporte/' + id);
  }

  deleteReporte(id: number | string) {
    return this.http.delete(this.baseUrl + 'reporte/' + id);
  }

  createReporte(reporte: Reporte) {
    return this.http.post(this.baseUrl + 'reporte/', reporte);
  }

  updateReporte(reporte: Reporte) {
    return this.http.put(this.baseUrl + 'reporte/' + reporte.id, reporte);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements  CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  renderOpcional(): boolean {    
    if (this.authService.islogin()) { return true; }
    return false;
  }

  logout(){
    this.authService.logout();
  }

  checkLogin(url: string): boolean {
    console.log(this.authService.isLoggedIn)
    if (this.authService.islogin()) { return true; }

    this.authService.redirectUrl = url;

    // Si no esta loggeado se redirige a la pantalla de login
    this.router.navigate(['/login']);
    return false;
  }
}

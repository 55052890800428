import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Security {
    constructor( ){}

    puedeVer(){
        if(localStorage.getItem('rol') === "ADMINISTRADOR"){
          return true;
        }else{
          return false;
        }
      }
    

    
}
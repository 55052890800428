import { Component, OnInit } from '@angular/core';
import { HorarioService } from '../../_services/horario.service';
import { EquipoService } from '../../_services/equipo.service';
import { CanchaService } from '../../_services/cancha.service';
import { Horario } from '../../_models/horario';
import { Equipo } from '../../_models/equipo';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2';
declare var $ :any; 


@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.css']
})
export class HorarioComponent implements OnInit {

  // Atributos de clase
  horarios:  Horario[];
  equipos = [];
  canchas = [];
  horarioForm: FormGroup;
  loading = false;
  submitted = false;
  flag_editar = false;
  modalTitleText: String;
  postOrPutHorario;
  searchText;

  pp: any;

  constructor(private horarioService: HorarioService, private equipoService: EquipoService, private canchaService: CanchaService,private formBuilder: FormBuilder) {
    // this.horarios = [
    //   {id: 1, id_equipo: {id: 1, nombre_equipo: 'Real Madrid'}, id_cancha: {id: 1, nombre_cancha: 'Valentin Gómez Farías'}, dia: "Domingo", hora: 700, estatus: 1},
    //   {id: 2, id_equipo: {id: 4, nombre_equipo: 'Juventus'}, id_cancha: {id: 2, nombre_cancha: 'San Patricio'}, dia: "Sábado", hora: 1330, estatus: 1}
    // ];

    // this.equipos = [
    //   {id: 1, nombre_equipo: 'Arsenal'},
    //   {id: 2, nombre_equipo: 'Barcelona'},
    //   {id: 3, nombre_equipo: 'Juventus'},
    //   {id: 4, nombre_equipo: 'Real Madrid'},
    //   {id: 5, nombre_equipo: 'Valladolid'}
    // ];

    // this.canchas = [
    //   {id: 1, nombre_cancha: 'Águilas Tarango'},
    //   {id: 2, nombre_cancha: 'Gómez Tarango'},
    //   {id: 3, nombre_cancha: 'San Patricio'},
    //   {id: 4, nombre_cancha: 'Valentin Gómez Farías'}
    // ];

   }

  ngOnInit() {

    this.getHorarios();

    this.getCanchas();

    // Inicializar campos de formulario con sus validaciones
    this.horarioForm = this.formBuilder.group({
      id: [''],
      id_equipo: ['', Validators.required],
      id_cancha: ['', Validators.required],
      dia: ['', Validators.required],
      hora: ['', Validators.required],
      estatus: true
    })
  }

  /*
    Funciones CRUDL con el servicio JugadorService:
  */

 getHorarios() {
  this.loading = true;
  this.horarios = [];
  this.horarioService.getHorarios().subscribe(
      res => {
        // this.horarios = res;
        // this.loading = false;
        // console.log(this.horarios);

          var horarios = res;
          console.log(horarios);

          this.loading = true;
          var min: Horario;
          var k = 0;
          for(var i=0; i<horarios.length;i++){
            for(var l = 0; l < horarios.length; l++){
              if(horarios[l].estatus != -1){
                min = horarios[l];
                k = l;
              }
            }
            for(var j=0; j<horarios.length;j++){
              if(min.id_cancha > horarios[j].id_cancha && horarios[j].estatus != -1){
                min = horarios[j];
                k = j;
              }
              if(min.id_cancha == horarios[j].id_cancha && horarios[j].estatus != -1){
                if(min.dia > horarios[j].dia){
                  min = horarios[j];
                  k = j;
                }
                if(min.dia == horarios[j].dia){
                  if(min.hora > horarios[j].hora){
                    min = horarios[j];
                    k = j;
                  }
                }
              }
            }
            this.horarios.push(min);
            horarios[k].estatus = -1;
          }
          this.getEquipos(this.horarios);
          this.loading = false;
      },
      err => console.error(err)
    );
}

getHorario(id: string) {
  this.horarioService.getHorario(id).subscribe(
      res => {
        console.log("Exito");
      },
      err => console.error(err)
    );
}

deleteHorario(horario: Horario){
  Swal.fire({
    title: "Eliminar Horario",
    text: "Estás seguro que deseas eliminar el horario?", 
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí. eliminar!'
  }).then((result) => {
    if(result.value){
      this.horarioService.deleteHorario(horario.id).subscribe(
        res => {
          Swal.fire(
            'Eliminado!',
            'El horario ha sido eliminado',
            'success'
          )
          this.getHorarios();
        },
        err => console.error(err)
      )
    }
  });
  this.getHorarios();
}

// getter para acceder a los campos del formulario
get f() { return this.horarioForm.controls; }

public openPostModal(){
  this.horarioForm.reset();
  this.modalTitleText = 'Registrar Horario';
  this.flag_editar = false;
  $("#horarioModal").modal("show");
}

onSubmit(){
  this.submitted = true;

  if(this.horarioForm.invalid){
    console.log(this.horarioForm);
    swal.fire({
      title: 'Todos los campos deben ser llenados',
      text: "",
      type: 'warning',
      confirmButtonText: 'ok'
    })
    return;
  }

  this.loading = true;
  if (this.modalTitleText === 'Registrar Horario'){
    this.postOrPutHorario = this.horarioService.createHorario(this.horarioForm.value)
    console.log(this.horarioForm.value)
  }else{
    this.postOrPutHorario = this.horarioService.updateHorario(this.horarioForm.value)
    console.log(this.horarioForm.value) 
  }

  this.postOrPutHorario
    .pipe(first())
    .subscribe(
      response => {
        swal.fire({
          title: 'Correcto!',
          text: response.message,
          type: 'success',
          confirmButtonText: 'ok'
        }),
        this.getHorarios();
        $("#horarioModal").modal("hide");
        this.horarioForm.reset();
        this.submitted = false;
        this.loading = false;
      },
      error => {
        // pending to show error
        console.error(error);
        this.loading = false;
      }
    )
}

public updateHorario(horario: Horario){
  this.modalTitleText = 'Editar Horario: ' + horario.equipo.nombre_equipo;
  this.flag_editar = true;

  console.log(horario);

  this.horarioForm.controls['id'].setValue(horario.id);
  this.horarioForm.controls['id_equipo'].setValue(horario.id_equipo);
  this.horarioForm.controls['id_cancha'].setValue(horario.id_cancha);
  this.horarioForm.controls['dia'].setValue(horario.dia);
  this.horarioForm.controls['hora'].setValue(horario.hora);
  this.horarioForm.controls['estatus'].setValue(true);
  
  $("#horarioModal").modal("show");
}


/*
  Funciones auxiliares
*/

getEquipos(horarios: Horario[]) {
  this.equipos = [];
  this.equipoService.getAll().subscribe(
      res => {
        // this.equipos = res;
        var equipos = res;

        this.loading = true;
        var min: Equipo;
        var k = 0;
        for(var i=0; i<equipos.length;i++){
          for(var l = 0; l < equipos.length; l++){
            if(equipos[l].estatus != -1){
              min = equipos[l];
              k = l;
            }
          }
          for(var j=0; j<equipos.length;j++){
            if(min.nombre_equipo > equipos[j].nombre_equipo && equipos[j].estatus != -1){
              min = equipos[j];
              k = j;
            }
          }

          // Agregar si el equipo no tiene horario previamente registrado
          var b = true;
          for(var m=0;m<horarios.length;m++){
            if(horarios[m].id_equipo == min.id){
              b = false;
            }
          }
          if(b){
            this.equipos.push(min);
          }
          
          equipos[k].estatus = -1;
        }
        console.log(horarios);
        console.log(this.equipos);
        this.loading = false;
      },
      err => console.error(err)
    );
}

getCanchas() {
  this.canchaService.getCanchas().subscribe(
      res => {
        this.canchas = res;
      },
      err => console.error(err)
    );
}

fecha_actual(){
  
  let fecha_actual = new Date();
  let mes_letra: string;

  let mes_numero = (fecha_actual.getMonth()+1);

  switch(mes_numero){
    case 1:
      mes_letra = "Enero";
      break;
    case 2:
      mes_letra = "Febrero";
      break;
    case 3:
      mes_letra = "Marzo";
      break;
    case 4:
      mes_letra = "Abril";
      break;
    case 5:
      mes_letra = "Mayo";
      break;
    case 6:
      mes_letra = "Junio";
      break;
    case 7:
      mes_letra = "Julio";
      break;
    case 8:
      mes_letra = "Agosto";
      break;
    case 9:
      mes_letra = "Septiembre";
      break;
    case 10:
      mes_letra = "Octubre";
      break;
    case 11:
      mes_letra = "Noviembre";
      break;
    case 12:
      mes_letra = "Diciembre";
      break; 
    default:
        mes_letra = "n/a";
  }

  let fecha = fecha_actual.getDate() + '-' +  mes_letra + '-' + fecha_actual.getFullYear();
  let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();
  
  return fecha + ' ' + hora; 
}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Aviso } from '../_models/aviso';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class AvisoService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getAvisos() {
    return this.http.get<Aviso[]>(this.baseUrl + 'aviso/');
  }

  deleteAviso(id: number | string) {
    return this.http.delete(this.baseUrl + 'aviso/'+id+'/');
  }

  createAviso(aviso: Aviso) {
    return this.http.post(this.baseUrl+'aviso/', aviso);
  }

  updateAviso(aviso: Aviso){
    return this.http.put(this.baseUrl + 'aviso/' + aviso.id, aviso);
  }

  getAvisosHome() {
    return this.http.get<Aviso[]>(this.baseUrl + 'avisohome/');
  }
}

import { Usuario } from "./usuario";

export class Equipo {
    id: number;
    id_usuario: number;
    id_categoria: number;
    nombre_equipo: string;
    color_playera: string;
    color_short: string;
    logo: any;
    grupo: number;
    estatus: number;
  usuario: any;

  constructor(){
    this.id = 0;
    this.id_usuario = 0;
    this.id_categoria = 0;
    this.nombre_equipo = "";
    this.color_playera = "ffffff";
    this.color_short = "ffffff";
    this.logo = "";
    this.grupo = 0;
    this.estatus = 0;

    this.usuario = new Usuario();
  }
}

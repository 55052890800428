import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import { ResponseContentType } from '@angular/http';
import { Equipo } from '../_models';
import { Observable } from 'rxjs';
import{ GlobalConstants } from '../common/global-constants';
 
@Injectable({
  providedIn: 'root'
})

export class EquipoService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getAllTabla() {
    return this.http.get<Equipo[]>(this.baseUrl + 'tabla_posiciones/equipo');
  }

  getAll() {
    return this.http.get<Equipo[]>(this.baseUrl + 'equipo/');
  }

  getEquiposIds(id_categoria: number) {
    return this.http.get<Equipo[]>(this.baseUrl + 'equipo/ids/'+id_categoria);
  }

  getEquipo(id: string) {
    return this.http.get<Equipo>(this.baseUrl+'equipo-detalle/'+id);
  }

  getEquipoCategoria(id_categoria: number) {
    return this.http.get<Equipo[]>(this.baseUrl+'equipo/categoria/'+id_categoria);
  }

  createEquipoUsuario(equipoUsuario: any){
    return this.http.post<any>(this.baseUrl + 'equipo/usuario', equipoUsuario);
  }

  postEquipo(equipo: Equipo){
    return this.http.post<any>(this.baseUrl + 'equipo/', equipo);
  }

  putEquipo(equipo: Equipo){
    /*let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
      headers: new HttpHeaders().set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT')
    };*/

    return this.http.put<any>(this.baseUrl + 'equipo/' + equipo.id, equipo);
  }

  putEquipoDelegado(equipo: Equipo, id: number|string){
    return this.http.put<any>(this.baseUrl + 'equipo-detalle/delegado/' + id, equipo);
  }

  deleteEquipo(id: number) {
    return this.http.delete<any>(this.baseUrl + 'equipo/' + id);
  }

  descargaRegistros(id: number): void{
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('authorization','Bearer '+ token);
    this.http.get(this.baseUrl + 'jugador/descarga/' + id,{headers, responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
            downloadLink.setAttribute('download', 'registros.pdf');
           document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
}
   
}

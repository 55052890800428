import { Component, OnInit, ɵConsole } from '@angular/core';
import { UsuarioService } from '../../_services/usuario.service';
import { Usuario } from '../../_models';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './usuario.component.html',
    styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {
    // Atributos de clase
    usuarios = [];
    userForm: FormGroup;
    loading = false;
    submitted = false;
    modalTitleText: String;
    postOrPutUser;
    searchText;
    p = 0;

    constructor(
        private usuarioService: UsuarioService,
        private formBuilder: FormBuilder
    ) { }

    // Funcion que se ejecuta al cargar el componente de Usuario
    ngOnInit() {
        this.getUsers();

        // Inicializar campos de formulario con sus validaciones
        this.userForm = this.formBuilder.group({
            id: [''],
            id_tipo_usuario: ['', Validators.required],
            username: ['', Validators.required],
            nombre_usuario: ['', Validators.required],
            apellidos_usuario: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(4)]],
            domicilio: ['', Validators.required],
            correo: ['', Validators.required],
            telefono_1: ['', Validators.required],
            telefono_2: [''],
            estatus: true
        })
    }


    fecha_actual() {

        let fecha_actual = new Date();
        let mes_letra: string;

        let mes_numero = (fecha_actual.getMonth() + 1);

        switch (mes_numero) {
            case 1:
                mes_letra = "Enero";
                break;
            case 2:
                mes_letra = "Febrero";
                break;
            case 3:
                mes_letra = "Marzo";
                break;
            case 4:
                mes_letra = "Abril";
                break;
            case 5:
                mes_letra = "Mayo";
                break;
            case 6:
                mes_letra = "Junio";
                break;
            case 7:
                mes_letra = "Julio";
                break;
            case 8:
                mes_letra = "Agosto";
                break;
            case 9:
                mes_letra = "Septiembre";
                break;
            case 10:
                mes_letra = "Octubre";
                break;
            case 11:
                mes_letra = "Noviembre";
                break;
            case 12:
                mes_letra = "Diciembre";
                break;
            default:
                mes_letra = "n/a";
        }

        let fecha = fecha_actual.getDate() + '-' + mes_letra + '-' + fecha_actual.getFullYear();
        let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();


        return fecha + ' ' + hora;
    }

    // getter para acceder a los campos del formulario
    get f() { return this.userForm.controls; }

    public openPostModal() {
        this.userForm.reset();
        this.modalTitleText = 'Registrar Usuario';
        $("#userModal").modal("show");
    }

    // Crear-Actualizar usuario (Submit del formulario de alta y editar usuario)
    onSubmit() {
        this.submitted = true;

        if (this.userForm.invalid) {
            swal.fire({
                title: 'Todos los campos deben ser llenados',
                text: "",
                type: 'warning',
                confirmButtonText: 'ok'
            })
            return;
        }

        this.loading = true;
        if (this.modalTitleText === 'Registrar Usuario')
            this.postOrPutUser = this.usuarioService.postUser(this.userForm.value)
        else
            this.postOrPutUser = this.usuarioService.putUser(this.userForm.value);

        this.postOrPutUser
            .pipe(first())
            .subscribe(
                response => {
                    swal.fire({
                        title: 'Correcto!',
                        text: response.message,
                        type: 'success',
                        confirmButtonText: 'ok'
                    }),
                        this.getUsers();
                    $("#userModal").modal("hide");
                    this.userForm.reset();
                    this.submitted = false;
                    // this.loading = false;

                },
                error => {
                    // pending to show error
                    console.error(error);
                    this.loading = false;
                }
            )
            // this.getUsers();
    }

    // Obtener usuarios
    getUsers() {
        this.loading = true;
        this.usuarios = [];
        this.usuarioService.getAll()
            .pipe(first())
            .subscribe(
                usuarios => {
                    this.usuarios = usuarios
                    this.loading = false;

                    // var usuarios = usuarios;

                    // this.loading = true;
                    // var min: Usuario;
                    // var k = 0;
                    // for(var i=0; i<usuarios.length;i++){
                    //     for(var l = 0; l < usuarios.length; l++){
                    //         if(usuarios[l].estatus != -1){
                    //             min = usuarios[l];
                    //             k = l;
                    //         }
                    //     }

                    //     for(var j=0; j<usuarios.length;j++){
                    //         if(min.nombre_usuario > usuarios[j].nombre_usuario && usuarios[j].estatus != -1){
                    //             min = usuarios[j];
                    //             k = j;
                    //         }
                    //     }
                    //     this.usuarios.push(min);
                    //     usuarios[k].estatus = -1;
                    // }
                    // this.loading = false;
                },
                error => {
                    console.error(error);
                }
            );
    }

    // Actualizar usuario
    public updateUser(user: Usuario) {
        console.log(user.correo);

        this.modalTitleText = 'Editar Usuario: ' + user.username;

        this.userForm.controls['id'].setValue(user.id);
        this.userForm.controls['id_tipo_usuario'].setValue(user.id_tipo_usuario);
        this.userForm.controls['username'].setValue(user.username);
        this.userForm.controls['nombre_usuario'].setValue(user.nombre_usuario);
        this.userForm.controls['apellidos_usuario'].setValue(user.apellidos_usuario);
        this.userForm.controls['password'].setValue(user.password);
        this.userForm.controls['domicilio'].setValue(user.domicilio);
        this.userForm.controls['correo'].setValue(user.correo);
        this.userForm.controls['telefono_1'].setValue(user.telefono_1);
        this.userForm.controls['telefono_2'].setValue(user.telefono_2);
        this.userForm.controls['estatus'].setValue(user.estatus);

        $("#userModal").modal("show");
    }

    // Eliminar usuario
    public deleteUser(user: Usuario) {
        Swal.fire({
            title: "Seguro que deseas eliminar al usuario " + user.username + " ?",
            text: "",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí. eliminar!'
        }).then((result) => {
            if (result.value) {
                this.usuarioService.deleteUser(user.id)
                    .pipe(first())
                    .subscribe(
                        response => {
                            swal.fire({
                                title: 'Correcto!',
                                text: response.message,
                                type: 'success',
                                confirmButtonText: 'ok'
                            }),
                                this.getUsers()
                        },
                        error => {
                            console.error(error);
                        }
                    )
            }
        });

    }
}

import { Component, OnInit } from '@angular/core';
import { RolJuegosService } from '../../_services/rol-juegos.service';
import { CategoriaService } from 'src/app/_services/categoria.service';
import { RolJuegos } from '../../_models/rol_juego';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2';

declare var $ :any;

@Component({
  selector: 'app-rol-juegos',
  templateUrl: './rol-juegos.component.html',
  styleUrls: ['./rol-juegos.component.css']
})
export class RolJuegosComponent implements OnInit {

  rolJuegosForm: FormGroup;
  rolJuegos = [];                 
  rolJuego: RolJuegos;      
        
  archivo: File;               
  archivo_name: string;

  categorias: any;
  categoriaSeleccionada: number;

  flag: boolean;
  hasRolJuegos: boolean;
  loading = true; 
  submitted = false;

  admin = false;

  constructor(
    private rolJuegosService: RolJuegosService,
    private categoriaService: CategoriaService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    if(localStorage.getItem('rol') == "ADMINISTRADOR")
      this.admin = true;

    this.getCategorias();
    
    this.rolJuegosForm = this.formBuilder.group({
      id: [''],
      id_categoria: ['', Validators.required],
      nombre: ['', Validators.required],
      archivo: [''],
      estatus: true
    })
  }

  getCategorias() {
    this.loading = true;
    this.categoriaService.getCategoriasTabla().subscribe(
        res => {
          this.categorias = res;
          this.loading = false;
        },
        err => console.error(err)
      );
  }

  getRolJuegos(){
    if(this.categoriaSeleccionada != null){
      this.loading = true;
      this.rolJuegos = [];
      this.rolJuegosService.getRolJuegos(this.categoriaSeleccionada).subscribe(
          res => {
            this.rolJuegos = res;
            this.flag = true;
            console.log(this.rolJuegos);
            console.log(this.rolJuegos.length);
            if(this.rolJuegos.length == 0)
              this.hasRolJuegos = false;
            else
            this.hasRolJuegos = true;
            this.loading = false;
          },
          err => console.error(err)
        );
    }
  }

  deleteRolJuegos(rolJuegos: RolJuegos){
    Swal.fire({
      title: "Eliminar Rol de Juegos",
      text: "Estás seguro que deseas eliminar el rol de juegos?", 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí. eliminar!'
    }).then((result) => {
      if(result.value){
        this.rolJuegosService.deleteRolJuegos(rolJuegos.id).subscribe(
          res => {
            Swal.fire(
              'Eliminado!',
              'El rol de juegos ha sido eliminado',
              'success'
            )
            this.getRolJuegos();
          },
          err => console.error(err)
        )
      }
    });
  }

  registrarRolJuegos(){
    $("#rolJuegosModal").modal("show");
  }

  onSubmit(){
    console.log(this.rolJuegosForm.value);

    this.submitted = true;
  
    if(this.rolJuegosForm.invalid){
      swal.fire({
        title: 'Todos los campos deben ser llenados',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }
  
    this.loading = true;
    var create = this.rolJuegosService.createRolJuegos(this.rolJuegosForm.value)

    create.pipe(first())
    .subscribe(
      response => {
        swal.fire({
          title: 'Correcto!',
          text: 'El rol de juegos ha sido registrado!',
          type: 'success',
          confirmButtonText: 'ok'
        }),
        this.getRolJuegos();
        $("#rolJuegosModal").modal("hide");
        this.rolJuegosForm.reset();
        this.submitted = false;
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    )
  }

  archivoSelected(event){
    this.archivo = <File> event.target.files[0];
    this.archivo_name = this.archivo.name;
    this.convertLogo(this.archivo,this.rolJuegosForm);
  }

  // Convierte imagen de identificación a base64
  convertLogo(file,equipoForm): any {
    let reader = new FileReader();
    let me: any;
    reader.readAsDataURL(file);
    reader.onload = function () {
      me = reader.result;
      equipoForm.controls['archivo'].setValue(reader.result);
      // equipo.logo = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  showPdf(pdf){
    var downloadLink = document.createElement("a");

    downloadLink.href = pdf;
    downloadLink.download = "rol_juegos.pdf";
    downloadLink.click();

    return downloadLink;
  }

  // getter para acceder a los campos del formulario
  get f() { return this.rolJuegosForm.controls; }


  fecha_actual(){
    let fecha_actual = new Date();
    let mes_letra: string;
    let mes_numero = (fecha_actual.getMonth()+1);
  
    switch(mes_numero){
      case 1:
        mes_letra = "Enero";
        break;
      case 2:
        mes_letra = "Febrero";
        break;
      case 3:
        mes_letra = "Marzo";
        break;
      case 4:
        mes_letra = "Abril";
        break;
      case 5:
        mes_letra = "Mayo";
        break;
      case 6:
        mes_letra = "Junio";
        break;
      case 7:
        mes_letra = "Julio";
        break;
      case 8:
        mes_letra = "Agosto";
        break;
      case 9:
        mes_letra = "Septiembre";
        break;
      case 10:
        mes_letra = "Octubre";
        break;
      case 11:
        mes_letra = "Noviembre";
        break;
      case 12:
        mes_letra = "Diciembre";
        break; 
      default:
          mes_letra = "n/a";
    }
  
    let fecha = fecha_actual.getDate() + '-' +  mes_letra + '-' + fecha_actual.getFullYear();
    let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();

    return fecha + ' ' + hora; 
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Categoria } from '../_models/categoria';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getCategoriasTabla(){
    return this.http.get<Categoria[]>(this.baseUrl + 'tabla_posiciones/categoria');
  }

  getCategoriasPartido(){
    return this.http.get<Categoria[]>(this.baseUrl + 'consulta_partidos/categoria');
  }

  getCategorias() {
    return this.http.get<Categoria[]>(this.baseUrl + 'categoria/');
  }

  getCategoria(id: string) {
    return this.http.get<Categoria>(this.baseUrl + 'categoria/' + id);
  }

  deleteCategoria(id: number | string) {
    return this.http.delete(this.baseUrl + 'categoria/' + id);
  }

  createCategoria(categoria: Categoria) {
    return this.http.post(this.baseUrl + 'categoria/', categoria);
  }

  updateCategoria(categoria: Categoria) {
    return this.http.put(this.baseUrl + 'categoria/' + categoria.id, categoria);
  }
}

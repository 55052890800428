import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import swal from 'sweetalert2'; 

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router,  private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem('token');
        if (token) {
          request = request.clone({
            setHeaders: {
              'Authorization': 'Bearer ' + token
            }
          });
        }
        if (!request.headers.has('Content-Type')) {
          request = request.clone({
            setHeaders: {
              'content-type': 'application/json'
            }
          });
        }
        request = request.clone({
          headers: request.headers.set('Accept', 'application/json')
        });
        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              console.log('event--->>>', event);
            }
            return event;
          }),
          catchError((error: HttpErrorResponse): Observable<any> => {
            console.log('Error -> error: ' + error.error + ' status: ' + error.status + ' statusText: '+ error.statusText + ' type: ' + error.type)
            if (error.status === 400) {
              swal.fire({
                type: 'warning',
                title: 'Petición mal formada',
                // text: '' + error.error.error + 'Mensaje: ' + error + ' ' + error.message + ' ' + error.error.message + ' ' + error.error
                text: 'Mensaje: ' + error.error.message
              });
              return of(error.message);
            }
            if (error.status === 401) {
              // swal.fire({
              //   type: 'warning',
              //   title: 'No autorizado',
              //   text: '' + error.error.error + 'Mensaje: ' + error + ' ' + error.message + ' ' + error.error.message + ' ' + error.error
              // });
              swal.fire({
                type: 'error',
                title: 'Credenciales inválidas',
                text: 'El nombre de usuario y/o la contraseña son incorrectos'
              })
              //this.router.navigate(['login']);
              return of(error.message);
            }
            else if (error.status === 403) {
              swal.fire({
                type: 'warning',
                title: 'Forbidden',
                text: '' + error.error.error + 'Mensaje: ' + error + ' ' + error.message + ' ' + error.error.message + ' ' + error.error
              });
              this.router.navigate(['']);
              return of(error.message);
            }
            else if (error.status === 404) {
              swal.fire({
                type: 'warning',
                title: 'No encontrado',
                text: '' + error.error.error + 'Mensaje: ' + error + ' ' + error.message + ' ' + error.error.message + ' ' + error.error
              });
              return of(error.message);
            }
            else if(error.statusText.includes("Unknown")){
              swal.fire({
                type: 'warning',
                title: 'Error Desconocido',
                text: 'Algo salió mal. El servidor no contesta',
              });
              return of(error.message);
            }
            else if (error.status === 412){
              this.router.navigate(['login']);
              this.authService.logout();
              swal.fire({
                type: 'warning',
                title: 'Tu sesión ha expirado',
                text: 'Por favor ingresa nuevamente',
              });
              return of(error.message);
            }else{swal.fire({
                  type: 'warning',
                  title: 'Algo ha salido mal',
                  // text: '' + error.error.error + 'Mensaje: ' + error + ' ' + error.message + ' ' + error.error.message + ' ' + error.error
                  text: error.error.message
                });
                console.log("")
                return of(error.message);
            }
            
             throw error;
          }));
    }

}
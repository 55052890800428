import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Castigo } from '../_models/castigo';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CastigoService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getCastigos() {
    return this.http.get<Castigo[]>(this.baseUrl + 'castigo/');
  }

  getCastigo(id: string) {
    return this.http.get<Castigo>(this.baseUrl + 'castigo/' + id);
  }

  deleteCastigo(id: number | string) {
    return this.http.delete(this.baseUrl + 'castigo/' + id);
  }

  createCastigo(castigo: Castigo) {
    return this.http.post(this.baseUrl + 'castigo/', castigo);
  }

  updateCastigo(castigo: Castigo) {
    return this.http.put(this.baseUrl + 'castigo/' + castigo.id, castigo);
  }
}

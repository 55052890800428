import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Usuario } from '../_models';
import{ GlobalConstants } from '../common/global-constants';
 
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Usuario[]>(this.baseUrl + 'usuario/');
  }

  getUser(id: string) {
    return this.http.get<Usuario>(this.baseUrl+'usuario/'+id);
  }

  getDelegados() {
    return this.http.get<Usuario[]>(this.baseUrl + 'usuario/delegado');
  }

  postUser(user: Usuario){
    return this.http.post<any>(this.baseUrl + 'usuario/', user);
  }

  putUser(user: Usuario){
    return this.http.put<any>(this.baseUrl + 'usuario/' + user.id, user);
  }

  putEquipoUser(user: Usuario){
    console.log(user);
    return this.http.put<any>(this.baseUrl + 'equipo-detalle/' + user.id, user);
  }

  actualizarUsuario(user: Usuario){
    return this.http.put<any>(this.baseUrl + 'equipo/usuario/' + user.id, user);
  }

  deleteUser(id: number) {
    return this.http.delete<any>(this.baseUrl + 'usuario/' + id);
  }
}

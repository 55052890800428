import { Component, OnInit, Input } from '@angular/core';
import { TorneoService } from '../../_services/torneo.service';
import { TipoTorneoService } from '../../_services/tipo-torneo.service';
import { CategoriaService } from '../../_services/categoria.service';
import { Torneo } from '../../_models/torneo';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2';
import { PartidoService } from 'src/app/_services/partido.service';
import { Router } from '@angular/router';

declare var $ :any;
 
@Component({
  selector: 'app-torneo',
  templateUrl: './torneo.component.html',  
  styleUrls: ['./torneo.component.css']
})
export class TorneoComponent implements OnInit {

  // Atributos de clase
  torneos = [];
  categorias = [];
  tipo_torneos = [];
  torneoForm: FormGroup;
  loading = false;
  submitted = false;
  modalTitleText: String;
  postOrPutTorneo;
  searchText;
  items = [];
  pageOfItems: Array<any>;

  p: any;
  pp: any;

  constructor(private torneoService: TorneoService, 
              private tipoTorneoService: TipoTorneoService,
              private categoriaService: CategoriaService,
              private partidoService: PartidoService,
              private formBuilder: FormBuilder,
              private router: Router) {

    // PRUEBAS

    // this.torneos = [
    //   {id: 1, id_tipo_torneo: {id: 1, tipo_torneo: 'Liga'}, id_categoria: {id: 1, nombre_categoria: 'Dominical Primera A'}, nombre_torneo: "Liga Dominical A 2020", fecha_inicio: "2020-02-20", estatus: 1},
    //   {id: 2, id_tipo_torneo: {id: 2, tipo_torneo: 'Copa'}, id_categoria: {id: 5, nombre_categoria: 'Dominical Primera D'}, nombre_torneo: "Copa Dominical D 2020", fecha_inicio: "2020-09-20", estatus: 1}
    // ];

    // this.categorias = [
    //   {id: 1, nombre_categoria: 'Dominical Primera Especial'},
    //   {id: 2, nombre_categoria: 'Dominical Primera A'},
    //   {id: 3, nombre_categoria: 'Dominical Primera B'},
    //   {id: 4, nombre_categoria: 'Dominical Primera C'},
    //   {id: 5, nombre_categoria: 'Sabatina Primera A'}
    // ];

    // this.tipo_torneos = [
    //   {id: 1, tipo_torneo: 'Liga'},
    //   {id: 2, tipo_torneo: 'Copa'},
    //   {id: 3, tipo_torneo: 'Campeón de Campeones'},
    //   {id: 4, tipo_torneo: 'Guadalupano'}
    // ];

   }

  ngOnInit() {
    this.getTorneos();

    this.getCategorias();

    this.getTipoTorneos();

    // Inicializar campos de formulario con sus validaciones
    this.torneoForm = this.formBuilder.group({
      id: [''],
      id_tipo_torneo: ['', Validators.required],
      id_categoria: ['', Validators.required],
      nombre_torneo: ['', Validators.required],
      fecha_inicio: ['', Validators.required],
      estatus: true
    })
  }

  /*
    Funciones CRUDL con el servicio JugadorService:
  */

  getTorneos() {
    this.loading = true;
    this.torneos = [];
    this.torneoService.getTorneos().subscribe(
        res => {
          this.torneos = res;
          this.loading = false;

          // var torneos = res;
          
          // this.loading = true;
          // var min: Torneo;
          // var k = 0;
          // for(var i=0; i<torneos.length;i++){
          //   for(var l = 0; l < torneos.length; l++){
          //     if(torneos[l].estatus != -1){
          //       min = torneos[l];
          //       k = l;
          //     }
          //   }
          //   for(var j=0; j<torneos.length;j++){
          //     if(min.fecha_inicio < torneos[j].fecha_inicio && torneos[j].estatus != -1){
          //       min = torneos[j];
          //       k = j;
          //     }
          //     if(min.fecha_inicio == torneos[j].fecha_inicio && torneos[j].estatus != -1){
          //       if(min.nombre_torneo < torneos[j].nombre_torneo && torneos[j].estatus != -1){
          //         min = torneos[j];
          //         k = j;
          //       }
          //     }
          //   }
          //   this.torneos.push(min);
          //   torneos[k].estatus = -1;
          // }

          this.loading = false;
        },
        err => {console.error(err);
        this.loading = false;
      }
      );
  }

  getTorneo(id: string) {
    this.torneoService.getTorneo(id).subscribe(
        res => {
          console.log("Exito");
        },
        err => console.error(err)
      );
  }

  deleteTorneo(torneo: Torneo){
    Swal.fire({
      title: "Eliminar Torneo",
      text: "Estás seguro que deseas eliminar el torneo " + torneo.nombre_torneo + " ?", 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí. eliminar!'
    }).then((result) => {
      if(result.value){
        this.torneoService.deleteTorneo(torneo.id).subscribe(
          res => {
            Swal.fire(
              'Eliminado!',
              'El torneo ' + torneo.nombre_torneo + ' ha sido eliminado',
              'success'
            )
            this.getTorneos();
          },
          err => console.error(err)
        )
      }
    });
    this.getTorneos();
  }

  // getter para acceder a los campos del formulario
  get f() { return this.torneoForm.controls; }

  public openPostModal(){
    this.torneoForm.reset();
    this.modalTitleText = 'Registrar Torneo';
    $("#torneoModal").modal("show");
  }

  onSubmit(){
    this.submitted = true;

    if(this.torneoForm.invalid){
      console.log(this.torneoForm);
      swal.fire({
        title: 'Todos los campos deben ser llenados',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }

    this.loading = true;
    if (this.modalTitleText === 'Registrar Torneo'){
      this.postOrPutTorneo = this.torneoService.createTorneo(this.torneoForm.value);
      console.log(this.torneoForm.value);
    }else{
      this.postOrPutTorneo = this.torneoService.updateTorneo(this.torneoForm.value)
      console.log(this.torneoForm.value)
    }
    
    this.postOrPutTorneo
      .pipe(first())
      .subscribe(
        response => {
          swal.fire({
            title: 'Correcto!',
            text: response.message,
            type: 'success',
            confirmButtonText: 'ok'
          }),
          this.getTorneos();
          $("#torneoModal").modal("hide");
          this.torneoForm.reset();
          this.submitted = false;
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.error(error);
        }
      )
  }

  public updateTorneo(torneo: Torneo){
    this.modalTitleText = 'Editar Torneo: ' + torneo.nombre_torneo;

    this.torneoForm.controls['id'].setValue(torneo.id);
    this.torneoForm.controls['id_tipo_torneo'].setValue(torneo.id_tipo_torneo);
    this.torneoForm.controls['id_categoria'].setValue(torneo.id_categoria);
    this.torneoForm.controls['nombre_torneo'].setValue(torneo.nombre_torneo);
    this.torneoForm.controls['fecha_inicio'].setValue(torneo.fecha_inicio);
    this.torneoForm.controls['estatus'].setValue(true);
    
    $("#torneoModal").modal("show");
  }


  /*
    Funciones auxiliares
  */

  getCategorias() {
    this.categoriaService.getCategorias().subscribe(
      res => {
        this.categorias = res;
      },
      err => console.error(err)
    );
  }

  getTipoTorneos() {
    this.tipoTorneoService.getTipoTorneos().subscribe(
      res => {
        this.tipo_torneos = res;
      },
      err => console.error(err)
    );

    console.log(this.tipo_torneos);
  }

  // GRUPOS TORNEO

  setGroups(torneo){
    this.router.navigate(['torneo-grupo/'+torneo.id]);
  }

  // FINALIZAR LIGA

  finalizarLiga(torneo){
    Swal.fire({
      title: "Finalizar Torneo de Liga",
      text: "Estás seguro que deseas finalizar el torneo " + torneo.nombre_torneo + " ?", 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí. finalizar!'
    }).then((result) => {
      if(result.value){
        this.loading = true;
        this.torneos = [];
        this.torneoService.finalizarTorneo(torneo.id).subscribe(
          res => {
            Swal.fire(
              'Finalizado!',
              'El torneo ' + torneo.nombre_torneo + ' ha sido finalizado',
              'success'
            )
            this.loading = false;
            this.getTorneos();
          },
          err => {
            this.loading = false;
            console.error(err);
          }
        )
      }
    });
  }

  fecha_actual(){
    
    let fecha_actual = new Date();
    let mes_letra: string;
  
    let mes_numero = (fecha_actual.getMonth()+1);
  
    switch(mes_numero){
      case 1:
        mes_letra = "Enero";
        break;
      case 2:
        mes_letra = "Febrero";
        break;
      case 3:
        mes_letra = "Marzo";
        break;
      case 4:
        mes_letra = "Abril";
        break;
      case 5:
        mes_letra = "Mayo";
        break;
      case 6:
        mes_letra = "Junio";
        break;
      case 7:
        mes_letra = "Julio";
        break;
      case 8:
        mes_letra = "Agosto";
        break;
      case 9:
        mes_letra = "Septiembre";
        break;
      case 10:
        mes_letra = "Octubre";
        break;
      case 11:
        mes_letra = "Noviembre";
        break;
      case 12:
        mes_letra = "Diciembre";
        break; 
      default:
          mes_letra = "n/a";
    }
  
    let fecha = fecha_actual.getDate() + '-' +  mes_letra + '-' + fecha_actual.getFullYear();
    let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();
    
    return fecha + ' ' + hora; 
  }


  detalleTorneo(torneo: Torneo){
    this.modalTitleText = 'Partidos del Torneo';

    this.partidoService.getPartidosTorneo(torneo.id, torneo.id_categoria).subscribe(
      res => {
        this.items = res;
      },
      err => console.error(err)
    );

    $("#partidosTorneo").modal("show");
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

}

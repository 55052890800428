import { Component, OnInit } from '@angular/core';
import { CategoriaService } from '../../_services/categoria.service';
import { Categoria } from '../../_models/categoria';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import {Security} from '../../_helpers/super-seguridad'
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})

export class CategoriaComponent implements OnInit {
  categorias: Categoria[];
  categoriaForm: FormGroup;
  loading = false;
  submitted = false;
  modalTitleText: String;

  lunes: number;
  martes: number;
  miercoles: number;
  jueves: number;
  viernes: number;
  sabado: number;
  domingo: number;

  postOrPutCategoria;

  cierto: boolean;
  searchText;

  pp: any;

  constructor(private categoriaService: CategoriaService, private formBuilder: FormBuilder, private security: Security) {
    // PRUEBAS

    // this.categorias = [
    //   { id: 1, nombre_categoria: 'Dominical Primera A', lunes: 1, martes: 0, miercoles: 0, jueves: 0, viernes: 1, sabado: 0, domingo: 0, duracion_partidos: 90, estatus: 1 },
    //   { id: 2, nombre_categoria: 'Dominical Primera D', lunes: 0, martes: 1, miercoles: 1, jueves: 0, viernes: 1, sabado: 0, domingo: 0, duracion_partidos: 90, estatus: 1 },
    // ];

  }



  ngOnInit() {
    this.getCategorias();
    this.cierto = this.security.puedeVer();

    // Inicializar campos de formulario con sus validaciones
    this.categoriaForm = this.formBuilder.group({
      id: [''],
      nombre_categoria: ['', Validators.required],
      lunes: [''],
      martes: [''],
      miercoles: [''],
      jueves: [''],
      viernes: [''],
      sabado: [''],
      domingo: [''],
      duracion_partidos: ['', Validators.required],
      estatus: true
    })

  }
  /*
      Funciones CRUDL con el servicio JugadorService:
    */

  getCategorias() {
    this.categorias = [];
    this.loading = true;
    this.categoriaService.getCategorias().subscribe(
        res => {
          this.categorias = res;
          this.loading = false;
        },
        err => console.error(err)
      );
  }

  getCategoria(id: string) {
    this.categoriaService.getCategoria(id).subscribe(
      res => {
        console.log("Exito");
      },
      err => console.error(err)
    );
  }

  deleteCategoria(categoria: Categoria) {
    Swal.fire({
      title: "Eliminar Categoría",
      text: "Estás seguro que deseas eliminar la categoría " + categoria.nombre_categoria + " ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí. eliminar!'
    }).then((result) => {
      if (result.value) {
        this.categoriaService.deleteCategoria(categoria.id).subscribe(
          res => {
            Swal.fire(
              'Eliminado!',
              'La categoría ' + categoria.nombre_categoria + ' ha sido eliminado',
              'success'
            )
            this.getCategorias();
          },
          err => console.error(err)
        )
      }
    });
    // this.getCategorias();
  }

  public getDias(categoria: Categoria) {

    if (categoria.lunes === 1)
      this.lunes = 1;
    if (categoria.martes === 1)
     this.martes = 1;
    if (categoria.miercoles === 1)
      this.miercoles = 1;
    if (categoria.jueves === 1)
      this.jueves = 1;
    if (categoria.viernes === 1)
     this.viernes = 1;
    if (categoria.sabado === 1)
      this.sabado = 1;
    if (categoria.domingo === 1)
      this.domingo = 1;

  }

  // getter para acceder a los campos del formulario
  get f() { return this.categoriaForm.controls; }

  public openPostModal() {
    this.categoriaForm.reset();
    this.modalTitleText = 'Registrar Categoría';
    $("#categoriaModal").modal("show");
  }

  onSubmit() {
    this.submitted = true;

    if (this.categoriaForm.invalid) {
      console.log(this.categoriaForm);
      swal.fire({
        title: 'Todos los campos deben ser llenados',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }

    // cambiar valores de días no selecionados de null a 0
    if (this.categoriaForm.value.lunes == null || this.categoriaForm.value.lunes == false)
      this.categoriaForm.value.lunes = 0;
    else
      this.categoriaForm.value.lunes = 1;

    if (this.categoriaForm.value.martes == null || this.categoriaForm.value.martes == false)
      this.categoriaForm.value.martes = 0;
    else
        this.categoriaForm.value.martes = 1;

    if (this.categoriaForm.value.miercoles == null || this.categoriaForm.value.miercoles == false)
      this.categoriaForm.value.miercoles = 0;
    else
      this.categoriaForm.value.miercoles = 1;

    if (this.categoriaForm.value.jueves == null || this.categoriaForm.value.jueves == false)
      this.categoriaForm.value.jueves = 0;
    else
      this.categoriaForm.value.jueves = 1;

    if (this.categoriaForm.value.viernes == null || this.categoriaForm.value.viernes == false)
      this.categoriaForm.value.viernes = 0;
    else
      this.categoriaForm.value.viernes = 1;

    if (this.categoriaForm.value.sabado == null || this.categoriaForm.value.sabado == false)
      this.categoriaForm.value.sabado = 0;
    else
      this.categoriaForm.value.sabado = 1;

    if (this.categoriaForm.value.domingo == null || this.categoriaForm.value.domingo == false)
      this.categoriaForm.value.domingo = 0;
    else
      this.categoriaForm.value.domingo = 1;

    console.log('Rentzsch Prueba 5');
    console.log(this.categoriaForm.value);

    if (this.categoriaForm.value.lunes == 0 && this.categoriaForm.value.martes == 0 && this.categoriaForm.value.miercoles == 0 && this.categoriaForm.value.jueves == 0 && this.categoriaForm.value.viernes == 0 && this.categoriaForm.value.sabado == 0 && this.categoriaForm.value.domingo == 0) {
      console.log(this.categoriaForm);
      swal.fire({
        title: 'Debes seleccionar al menos un día',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }

    // this.loading = true;
    if (this.modalTitleText === 'Registrar Categoría'){
      this.postOrPutCategoria= this.categoriaService.createCategoria(this.categoriaForm.value)
      // console.log(this.categoriaForm.value)
    }else{
      this.postOrPutCategoria = this.categoriaService.updateCategoria(this.categoriaForm.value)
      // console.log(this.categoriaForm.value)
    }

    this.postOrPutCategoria
      .pipe(first())
      .subscribe(
        response => {
          swal.fire({
            title: 'Correcto!',
            // text: response.message,
            text: 'La categoría ' + this.categoriaForm.value.nombre_categoria + ' ha sido guardada exitosamente!',
            type: 'success',
            confirmButtonText: 'ok'
          }),
          this.getCategorias();
          $("#categoriaModal").modal("hide");
          this.categoriaForm.reset();
          this.submitted = false;
          // this.loading = false;
        },
        error => {
          // pending to show error
          console.error(error);
          this.loading = false;
        }
      );
  }

  public updateCategoria(categoria: Categoria) {
    this.modalTitleText = 'Editar Categoria: ' + categoria.nombre_categoria;
    this.getDias(categoria);
    console.log(categoria);

    this.categoriaForm.controls['id'].setValue(categoria.id);
    this.categoriaForm.controls['nombre_categoria'].setValue(categoria.nombre_categoria);
    this.categoriaForm.controls['lunes'].setValue(categoria.lunes);
    this.categoriaForm.controls['martes'].setValue(categoria.martes);
    this.categoriaForm.controls['miercoles'].setValue(categoria.miercoles);
    this.categoriaForm.controls['jueves'].setValue(categoria.jueves);
    this.categoriaForm.controls['viernes'].setValue(categoria.viernes);
    this.categoriaForm.controls['sabado'].setValue(categoria.sabado);
    this.categoriaForm.controls['domingo'].setValue(categoria.domingo);
    this.categoriaForm.controls['duracion_partidos'].setValue(categoria.duracion_partidos);
    this.categoriaForm.controls['estatus'].setValue(true);

    $("#categoriaModal").modal("show");
  }


  /*
    Funciones auxiliares
  */

  fecha_actual() {

    let fecha_actual = new Date();
    let mes_letra: string;

    let mes_numero = (fecha_actual.getMonth() + 1);

    switch (mes_numero) {
      case 1:
        mes_letra = "Enero";
        break;
      case 2:
        mes_letra = "Febrero";
        break;
      case 3:
        mes_letra = "Marzo";
        break;
      case 4:
        mes_letra = "Abril";
        break;
      case 5:
        mes_letra = "Mayo";
        break;
      case 6:
        mes_letra = "Junio";
        break;
      case 7:
        mes_letra = "Julio";
        break;
      case 8:
        mes_letra = "Agosto";
        break;
      case 9:
        mes_letra = "Septiembre";
        break;
      case 10:
        mes_letra = "Octubre";
        break;
      case 11:
        mes_letra = "Noviembre";
        break;
      case 12:
        mes_letra = "Diciembre";
        break;
      default:
        mes_letra = "n/a";
    }

    let fecha = fecha_actual.getDate() + '-' + mes_letra + '-' + fecha_actual.getFullYear();
    let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();

    return fecha + ' ' + hora;
  }
}

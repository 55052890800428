import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Jugador_Equipo } from '../_models/jugador_equipo';
import { Jugador } from '../_models/jugador';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class JugadorEquipoService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getJugadorEquipo() {
    return this.http.get<Jugador_Equipo[]>(this.baseUrl + 'jugador_equipo/');
  }

  getJugador(id_equipo, id_jugador) {
    return this.http.get<Jugador>(this.baseUrl + 'jugador_equipo/' + id_equipo + "/" + id_jugador);
  }

}

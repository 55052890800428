import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Torneo } from '../_models/torneo';
import{ GlobalConstants } from '../common/global-constants';
import { TablaPosicion } from '../_models/tabla-posicion';

@Injectable({
  providedIn: 'root'
})
export class TorneoService {
  
  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getTorneosTabla() {
    return this.http.get<Torneo[]>(this.baseUrl + 'tabla_posiciones/torneo');
  }

  getTorneos() {
    return this.http.get<Torneo[]>(this.baseUrl + 'torneo/');
  }

  getTorneo(id: string) {
    return this.http.get<Torneo>(this.baseUrl+'torneo/'+id);
  }

  deleteTorneo(id: number | string) {
    return this.http.delete(this.baseUrl+'torneo/'+id);
  }

  createTorneo(torneo: Torneo) {
    return this.http.post(this.baseUrl+'torneo/', torneo);
  }

  updateTorneo(torneo: Torneo){
    return this.http.put(this.baseUrl+'torneo/'+ torneo.id, torneo);
  }

  getTorneosByTipoAndCategoria(id_tipo_torneo: number, categoria: any) {
    return this.http.get<Torneo[]>(this.baseUrl + `torneo/tipoTorneo/${id_tipo_torneo}/categoria/${categoria.id}`);
 }

 getTorneosByTipoAndIdCategoria(id_tipo_torneo: number, id_categoria: any) {
  return this.http.get<Torneo[]>(this.baseUrl + `torneo/tipoTorneo/${id_tipo_torneo}/categoria/${id_categoria}`);
}


 getPartidoTorneosByTipoAndCategoria(id_tipo_torneo: number, categoria: any) {
  return this.http.get<Torneo[]>(this.baseUrl + `consulta_partidos/torneo/tipoTorneo/${id_tipo_torneo}/categoria/${categoria.id}`);
}

 getTorneosByIdTipoAndIdCategoria(id_tipo_torneo: number, id_categoria: number) {
  return this.http.get<Torneo[]>(this.baseUrl + `torneo/tipoTorneo/${id_tipo_torneo}/categoria/${id_categoria}`);
}

getPartidosTorneosByIdTipoAndIdCategoria(id_tipo_torneo: number, id_categoria: number) {
  return this.http.get<Torneo[]>(this.baseUrl + `consulta_partidos/torneo/tipoTorneo/${id_tipo_torneo}/categoria/${id_categoria}`);
}

setGrupos(tablaPosicion: TablaPosicion[]){
  return this.http.post<any>(this.baseUrl + `torneo/setGrupos`,  tablaPosicion);
}

finalizarTorneo(id: number | string){
  return this.http.delete<any>(this.baseUrl + `torneo/finalizado/`+id);
}

getTorneoFinalizado(id: number | string){
  return this.http.get<TablaPosicion[]>(this.baseUrl + `tabla_posiciones/finalizado/`+id);
}

}

import { Component, OnInit } from '@angular/core';
import { CastigoService } from '../../_services/castigo.service';
import { EquipoService } from '../../_services/equipo.service';
import { JugadorService } from '../../_services/jugador.service';
import { ReporteService } from '../../_services/reporte.service';
import { JugadorEquipoService } from '../../_services/jugador-equipo.service';
import { Jugador_Equipo } from '../../_models/jugador_equipo';
import { Jugador } from '../../_models/jugador';
import { Castigo } from '../../_models/castigo';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { Equipo } from 'src/app/_models';
import { Security } from 'src/app/_helpers/super-seguridad';
declare var $: any;

@Component({
    selector: 'app-castigo',
    templateUrl: './castigo.component.html',
    styleUrls: ['./castigo.component.css']
})
export class CastigoComponent implements OnInit {
    castigos: Castigo[];
    equipos = [];
    jugadores = [];
    reportes = [];
    castigoForm: FormGroup;
    loading = false;
    loadingEquipos = false;
    loadingJugadores = false;
    submitted = false;
    modalTitleText: String;
    postOrPutCastigo;

    jugador_equipo: Jugador_Equipo[];
    equipoSeleccionado: number;

    isAdministrador: boolean;
    isLogged: boolean;
    searchText;

    pp: any;
    
    constructor(
        private castigoService: CastigoService, 
        private equipoService: EquipoService, 
        private jugadorService: JugadorService, 
        private reporteService: ReporteService, 
        private formBuilder: FormBuilder,
        private jugadorEquipoService: JugadorEquipoService,
        private security: Security
        ) {

    }


    ngOnInit() {

        this.isAdministrador = this.security.puedeVer();
        this.getCastigos();

        // Inicializar campos de formulario con sus validaciones
        this.castigoForm = this.formBuilder.group({
            id: [''],
            id_reporte: [''],
            id_equipo: [''],
            id_jugador: [''],
            fecha_inicio: ['', Validators.required],
            fecha_fin: ['', Validators.required],
            motivo: ['', Validators.required],
            estatus: true
        })

    }
    /*
        Funciones CRUDL con el servicio CastigoService:
      */

    getCastigos() {
        this.loading = true;
        this.castigos = [];
        this.castigoService.getCastigos().subscribe(
            res => {
                this.castigos = res;
                this.castigos.reverse();
                this.loading = false;
            },
            err => console.error(err)
        );
    }

    getCastigo(id: string) {
        this.castigoService.getCastigo(id).subscribe(
            res => {
                console.log("Exito");
            },
            err => console.error(err)
        );
    }

    deleteCastigo(castigo: Castigo) {
        Swal.fire({
            title: "Eliminar Castigo",
            text: "Estás seguro que deseas eliminar el castigo con el siguiente motivo: " + castigo.motivo + " ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí. eliminar!'
        }).then((result) => {
            if (result.value) {
                this.castigoService.deleteCastigo(castigo.id).subscribe(
                    res => {
                        Swal.fire(
                            'Eliminado!',
                            'El castigo ha sido eliminado',
                            'success'
                        )
                        this.getCastigos();
                    },
                    err => console.error(err)
                )
            }
        });
        this.getCastigos();
    }

    // getter para acceder a los campos del formulario
    get f() { return this.castigoForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.castigoForm.invalid) {
            console.log(this.castigoForm);
            swal.fire({
                title: 'Todos los campos deben ser llenados',
                text: "",
                type: 'warning',
                confirmButtonText: 'ok'
            })
            return;
        }

        console.log(this.castigoForm.value);

        this.loading = true;
        if (this.modalTitleText === 'Registrar Castigo') {
            console.log(this.castigoForm.value)
            this.postOrPutCastigo = this.castigoService.createCastigo(this.castigoForm.value)
        }
        else {
            console.log(this.castigoForm.value)
            this.postOrPutCastigo = this.castigoService.updateCastigo(this.castigoForm.value)
        }
             this.postOrPutCastigo
               .pipe(first())
               .subscribe(
                 response => {
                   swal.fire({
                     title: 'Correcto!',
                     text: response.message,
                     type: 'success',
                     confirmButtonText: 'ok'
                   }),
                   this.getCastigos();
                   $("#castigoModal").modal("hide");
                   this.castigoForm.reset();
                   this.submitted = false;
                   this.loading = false;
                 },
                 error => {
                   // pending to show error
                   console.error(error);
                   this.loading = false;
                 }
               )
               this.equipos = [];
               this.jugadores = [];
               this.jugador_equipo = [];
            //    this.equipoSeleccionado = 0;
        
    }

    public updateCastigo(castigo: Castigo) {
        this.modalTitleText = 'Actualizar Castigo';

        this.castigoForm.controls['id'].setValue(castigo.id);
        this.castigoForm.controls['id_reporte'].setValue(castigo.id_reporte);
        this.castigoForm.controls['id_equipo'].setValue(castigo.id_equipo);
        this.castigoForm.controls['id_jugador'].setValue(castigo.id_jugador);
        this.castigoForm.controls['motivo'].setValue(castigo.motivo);
        this.castigoForm.controls['fecha_inicio'].setValue(castigo.fecha_inicio);
        this.castigoForm.controls['fecha_fin'].setValue(castigo.fecha_fin);
        this.castigoForm.controls['estatus'].setValue(true);
        this.equipoSeleccionado = castigo.id_equipo;
        this.getJugadoresEquipo();

        $("#castigoModal").modal("show");
        this.getEquipos();
    }


    /*
      Funciones auxiliares
    */

    getJugadoresEquipo(){
        this.getJugadores();
        this.getReportes();
    }

   getJugadorEquipo(jugadores: Jugador[]) {
    this.loadingJugadores = true;
    this.loading = true;
    this.jugador_equipo = [];
    this.jugadorEquipoService.getJugadorEquipo().subscribe(
        res => {
          this.jugador_equipo = res;

          console.log(this.jugador_equipo);
          console.log(this.equipoSeleccionado);
          console.log(jugadores);

          for(var i=0; i<jugadores.length;i++){
            for(var j=0; j<this.jugador_equipo.length;j++){
              if(jugadores[i].id == this.jugador_equipo[j].id_jugador){
                if(this.equipoSeleccionado == this.jugador_equipo[j].id_equipo){
                  this.jugadores.push(jugadores[i]);
                }
              }
            }
          }
          
          
          this.loadingJugadores = false;
          this.loading = false;
          $("#castigoModal").modal("hide");
          $("#castigoModal").modal("show");
        },
        err => {
            console.error(err);
            this.loading = false;
            this.loadingJugadores = false;
        }
      );
  }

    getEquipos() {
        console.log("LOADING EQUIPOS: " + this.loadingEquipos);
        
        this.loadingEquipos = true;
        // this.loadingEquipos = true;
        this.equipoService.getAll().subscribe(
            res => {
                // this.equipos = res;
                var equipos = res;

          this.loading = false;
          var min: Equipo;
          var k = 0;
          for(var i=0; i<equipos.length;i++){
            for(var l = 0; l < equipos.length; l++){
              if(equipos[l].estatus != -1){
                min = equipos[l];
                k = l;
              }
            }
            for(var j=0; j<equipos.length;j++){
              if(min.nombre_equipo > equipos[j].nombre_equipo && equipos[j].estatus != -1){
                min = equipos[j];
                k = j;
              }
            }
            this.equipos.push(min);
            equipos[k].estatus = -1;
          }
          this.loading = false;
          this.loadingEquipos = false;
          $("#castigoModal").modal("hide");
          $("#castigoModal").modal("show");
        },
            err => {
                console.error(err);
                this.loading = false;
                this.loadingEquipos = false;
            }
        );
    }

    public openPostModal() {
        this.castigoForm.reset();
        this.modalTitleText = 'Registrar Castigo';
        this.getEquipos();
        $("#castigoModal").modal("show");
    }

    getJugadores() {
        
        this.loadingJugadores = true;
        this.jugadorService.getJugadores(this.equipoSeleccionado).subscribe(
        // this.jugadorService.getJugadores().subscribe(
            res => {
                this.jugadores = res;
                // this.getJugadorEquipo(res);
                
                this.loadingJugadores = false;
            },
            err => {
                console.error(err)
                this.loadingJugadores = false;
            }
        );
    }

    getReportes() {
        this.reporteService.getReportes().subscribe(
            res => {
                this.reportes = res;
                console.log(this.reportes);
            },
            err => console.error(err)
        );
    }

    mostrarMotivo(motivo: string){
        Swal.fire(
          'Motivo del Castigo:',
          motivo
        )
      }

    mostrarReporte(id_reporte: string){
        this.reporteService.getReporte(id_reporte).subscribe(
            res => {
                var reporte = res;
                Swal.fire(
                    'Reporte del Castigo:',
                    reporte.descripcion
                  )
            },
            err => console.error(err)
        );
      }

    fecha_actual() {

        let fecha_actual = new Date();
        let mes_letra: string;

        let mes_numero = (fecha_actual.getMonth() + 1);

        switch (mes_numero) {
            case 1:
                mes_letra = "Enero";
                break;
            case 2:
                mes_letra = "Febrero";
                break;
            case 3:
                mes_letra = "Marzo";
                break;
            case 4:
                mes_letra = "Abril";
                break;
            case 5:
                mes_letra = "Mayo";
                break;
            case 6:
                mes_letra = "Junio";
                break;
            case 7:
                mes_letra = "Julio";
                break;
            case 8:
                mes_letra = "Agosto";
                break;
            case 9:
                mes_letra = "Septiembre";
                break;
            case 10:
                mes_letra = "Octubre";
                break;
            case 11:
                mes_letra = "Noviembre";
                break;
            case 12:
                mes_letra = "Diciembre";
                break;
            default:
                mes_letra = "n/a";
        }

        let fecha = fecha_actual.getDate() + '-' + mes_letra + '-' + fecha_actual.getFullYear();
        let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();

        return fecha + ' ' + hora;
    }
}


import { Component, OnInit } from '@angular/core';
import { EquipoService } from '../../_services/equipo.service';
import { CategoriaService } from '../../_services/categoria.service';
import { Equipo } from '../../_models/equipo';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UsuarioService } from '../../_services/usuario.service';
import { Usuario } from '../../_models/usuario';
import { Router } from '@angular/router';
import {AuthGuard} from '../login/auth.guard';

import { ActivatedRoute } from '@angular/router'
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2';

import {CroppedEvent} from 'ngx-photo-editor';

declare var $ :any;

@Component({
  selector: 'app-equipo-detalle',
  templateUrl: './equipo-detalle.component.html',
  styleUrls: ['./equipo-detalle.component.css']
})
export class EquipoDetalleComponent implements OnInit {

  equipo: Equipo = new Equipo();
  id_equipo: any;
  loading = true;
  textButton = ""; 
  
  usuario_estatus: number;
  userForm: FormGroup;
  submitted = false;
  isAdmin = false;

  equipoForm: FormGroup;
  logo: File;               
  logo_name: string;
  imageChangedEvent: any;
  base64: any;

  usuario_correo: String;

  constructor(
    private equipoService: EquipoService, 
    private usuarioService: UsuarioService,
    private categoriaService: CategoriaService, 
    private authGuard: AuthGuard,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
    ) { 
      console.log(this.equipo);
    }

  ngOnInit() {

    if(localStorage.getItem('rol') == "DELEGADO")
      this.id_equipo = localStorage.getItem('id_equipo')
    else{
      this.id_equipo = this.route.snapshot.paramMap.get('id');
      this.isAdmin = true;
    }

    this.userForm = this.formBuilder.group({
        id: [''],
        id_tipo_usuario: ['', Validators.required],
        username: ['', Validators.required],
        nombre_usuario: ['', Validators.required],
        apellidos_usuario: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(4)]],
        domicilio: ['', Validators.required],
        correo: ['', Validators.required],
        telefono_1: ['', Validators.required],
        telefono_2: [''],
        estatus: true
    })

    this.equipoForm = this.formBuilder.group({
      id: [''],
      id_usuario: [''],
      id_categoria: ['', Validators.required],
      nombre_equipo: ['', Validators.required],
      color_playera: [''],
      color_short: [''],
      logo: [''],
      grupo: [''],
      estatus: true
    })
    this.logo_name = 'Selecciona una imagen...';
    
    console.log(this.id_equipo);
    this.getEquipo(this.id_equipo);
  }

  getEquipo(id: string){
    this.loading = true;
      this.equipo = new Equipo();
      this.equipoService.getEquipo(id).subscribe(
          res => {
            this.equipo = res;
            this.loading = false;
            console.log(this.equipo);

            this.equipoForm.controls['color_playera'].setValue(this.equipo.color_playera);
            this.equipoForm.controls['color_short'].setValue(this.equipo.color_short);
            this.equipoForm.controls['logo'].setValue(this.equipo.logo);
            console.log(this.equipoForm.value);

            this.actualizarUsuario(this.equipo);
          },
          err => console.error(err)
        );
  }

  actualizarUsuario(equipo: Equipo){
    if(this.equipo.usuario.estatus == 2){
      this.userForm.controls['id'].setValue(equipo.usuario.id);
      this.userForm.controls['id_tipo_usuario'].setValue(equipo.usuario.id_tipo_usuario);
      this.userForm.controls['username'].setValue(equipo.usuario.username);
      this.userForm.controls['nombre_usuario'].setValue(equipo.usuario.nombre_usuario);
      this.userForm.controls['apellidos_usuario'].setValue(equipo.usuario.apellidos_usuario);
      // this.userForm.controls['password'].setValue(equipo.usuario.password);
      this.userForm.controls['domicilio'].setValue(equipo.usuario.domicilio);
      this.userForm.controls['correo'].setValue(equipo.usuario.correo);
      this.userForm.controls['telefono_1'].setValue(equipo.usuario.telefono_1);
      this.userForm.controls['telefono_2'].setValue(equipo.usuario.telefono_2);
      this.userForm.controls['estatus'].setValue(equipo.usuario.estatus);

      this.usuario_correo = equipo.usuario.correo;
      $("#actualizarUsuarioModal").modal("show");
    }
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;

    if (this.userForm.invalid) {
        swal.fire({
            title: 'Todos los campos deben ser llenados',
            text: "",
            type: 'warning',
            confirmButtonText: 'ok'
        })
        
        this.loading = false;
        return;
    }

    this.userForm.controls['estatus'].setValue(1);

    var correo_change = false;
    var message = 'Correcto!';

    var correo: String = this.userForm.controls['correo'].value;

    console.log(this.usuario_correo != correo);
    console.log(this.usuario_correo);
    console.log(correo);
    console.log(this.userForm.value);
    
    if(this.usuario_correo != correo){
      correo_change = true;
      message = message + " Es necesario volver a iniciar sesión!"
    }
      
    var putUser = this.usuarioService.putEquipoUser(this.userForm.value);

    putUser
      .pipe(first())
      .subscribe(
          response => {
              swal.fire({
                  title: message,
                  text: response.message,
                  type: 'success',
                  confirmButtonText: 'ok'
              }),

              $("#actualizarUsuarioModal").modal("hide");
              this.userForm.reset();
              this.submitted = false;
              this.loading = false;

              if(correo_change){
                this.router.navigate(['/']);
                //Swal.fire('Es necesario iniciar sesión nuevamente');
                return this.authGuard.logout();
                
              }else{
                this.getEquipo(this.id_equipo);
              }
              
              
          },
          error => {
              console.error(error);
              this.submitted = false;
              this.loading = false;
          }
      )
      // this.getUsers();
  }

  // ACTUALIZAR EQUIPO

  public openModalEquipo(){ 
    $("#equipoModal").modal("show");
    this.textButton = "Actualizar";
  }

  updateEquipo(){
    this.submitted = true;
    this.loading = true;
    this.textButton = "Actualizando...";
    console.log(this.loading);
    console.log(this.equipoForm.value);
    console.log(this.id_equipo);
    let postOrPutEquipo = this.equipoService.putEquipoDelegado(this.equipoForm.value, this.id_equipo)
    postOrPutEquipo
      .pipe(first())
      .subscribe(
        response => {
          this.submitted = false;
          this.loading = false;
          
          $("#equipoModal").modal("hide");

          this.equipoForm.reset();
          this.equipoForm.controls['color_playera'].setValue(this.equipo.color_playera);
          this.equipoForm.controls['color_short'].setValue(this.equipo.color_short);
          this.equipoForm.controls['logo'].setValue(this.equipo.logo);
          this.logo_name = 'Selecciona una imagen...';

          this.getEquipo(this.id_equipo);
          
          swal.fire({
            title: 'Correcto!',
            text: response.message,
            type: 'success',
            confirmButtonText: 'ok'
          })
          
        },
        error => {
          // pending to show error
          console.error(error);
          this.loading = false;
        }
      )
  }

  logoSelected(event){
    this.logo = <File> event.target.files[0];
    this.logo_name = this.logo.name;
    this.convertLogo(this.logo,this.equipoForm);
  }

  // Convierte imagen de identificación a base64
  convertLogo(file,equipoForm): any {
    let reader = new FileReader();
    let me: any;
    reader.readAsDataURL(file);
    reader.onload = function () {
      me = reader.result;
      equipoForm.controls['logo'].setValue(reader.result);
      // equipo.logo = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: CroppedEvent) {
    this.base64 = event.base64;
    // this.jugador.foto = this.base64; 
    this.equipoForm.controls['logo'].setValue(this.base64);
    this.logo_name = "Logo seleccionado!";
  }

  // getter para acceder a los campos del formulario
  get f() { return this.userForm.controls; }

  regresarAEquipos(){
    this.router.navigate(['/equipo']);
  }

  redirectJugadores(){
    this.router.navigate(['/jugador']);
  }

}

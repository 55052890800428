import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Jugador } from '../_models/jugador';
import{ GlobalConstants } from '../common/global-constants';
import { JugadorFecha } from '../_models/jugador_fecha';

@Injectable({
  providedIn: 'root'
})
export class JugadorService {


  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getJugadores(id: number | string) {
    return this.http.get<Jugador[]>(this.baseUrl + 'jugador/'+id);
  }

  // getJugadores() {
  //   return this.http.get<Jugador[]>(this.baseUrl + 'jugador/');
  // }

  getJugador(id: string) {
    return this.http.get<Jugador>(this.baseUrl+'jugador/id/'+id);
  }

  deleteJugador(id: number | string) {
    return this.http.delete(this.baseUrl+'jugador/'+id);
  }

  createJugador(jugador: Jugador) {
    return this.http.post(this.baseUrl+'jugador/', jugador);
  }

  updateJugador(id: number, jugador: Jugador, id_equipo: number){
    return this.http.put(this.baseUrl+'jugador/'+id+'/'+id_equipo, jugador);
  }

  getJugadorImg(id_jugador: number | string) {
    return this.http.get<Jugador>(this.baseUrl+'jugador/'+'id'+'/'+id_jugador);
  }

  getJugadorBitacora(id: number | string) {
    return this.http.get<JugadorFecha>(this.baseUrl+'jugador/bitacora/'+id);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RolJuegos } from '../_models/rol_juego';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class RolJuegosService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getRolJuegos(id_categoria: number | string) {
    return this.http.get<RolJuegos[]>(this.baseUrl + 'rol-juegos/categoria/' + id_categoria);
  }

  deleteRolJuegos(id: number | string) {
    return this.http.delete(this.baseUrl + 'rol-juegos/'+id);
  }

  createRolJuegos(rolJuegos: RolJuegos) {
    return this.http.post(this.baseUrl+'rol-juegos/', rolJuegos);
  }
}

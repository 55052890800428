import { Component, OnInit } from '@angular/core';
import { ReporteService } from '../../_services/reporte.service';
import { EquipoService } from '../../_services/equipo.service';
import { CanchaService } from '../../_services/cancha.service';
import { JugadorService } from '../../_services/jugador.service';
import { JugadorEquipoService } from '../../_services/jugador-equipo.service';
import { Jugador } from '../../_models/jugador';
import { Reporte } from '../../_models/reporte'; 
import { CastigoService } from '../../_services/castigo.service';
import { Castigo } from '../../_models/castigo';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { Equipo } from 'src/app/_models';
import { Security } from 'src/app/_helpers/super-seguridad';
declare var $: any;

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {
  reportes: Reporte[];
  equipos = [];
  equipos_reporte = [];
  canchas = [];
  reporteForm: FormGroup;

  loading = false;
  submitted = false;
  modalTitleText: String;
  postOrPutReporte;
  searchText;

  pp: any;

  // AGREGAR CASTIGO
  castigoForm: FormGroup;
  jugadores = [];
  id_reporte_castigo: number;
  id_rival_castigo: number;

  castigos: Castigo[];
  postOrPutCastigo;

  isAdministrador: boolean;
  isLogged: boolean;

  // Variables para Diferenciar Administrador de Delegado
  id_equipoA = 0;

    constructor(
      private reporteService: ReporteService, 
      private equipoService: EquipoService, 
      private canchaService: CanchaService, 
      private formBuilder: FormBuilder,
      private jugadorService: JugadorService,
      private jugadorEquipoService: JugadorEquipoService,
      private castigoService: CastigoService,
      private security: Security
      ) {
   
  }


  ngOnInit() {

    this.isAdministrador = this.security.puedeVer();

    this.getReportes();
    this.getCanchas();
    this.getEquipos();
    // this.getJugadores();

    // Inicializar campos de formulario con sus validaciones
    this.reporteForm = this.formBuilder.group({
      id: [''],
      id_cancha: ['', Validators.required],
      id_equipoA: [''],
      id_equipoB: ['', Validators.required],
      fecha_reporte: [''],
      fecha_incidente: ['', Validators.required],
      descripcion: ['', Validators.required],
      estatus: true
    })

    this.castigoForm = this.formBuilder.group({
      id: [''],
      id_reporte: [''],
      id_equipo: [''],
      id_jugador: [''],
      fecha_inicio: ['', Validators.required],
      fecha_fin: ['', Validators.required],
      motivo: ['', Validators.required],
      estatus: true
  })

  }
  /*
      Funciones CRUDL con el servicio JugadorService:
    */

  getReportes() {
    this.loading = true;
    this.reportes = [];
    this.reporteService.getReportes().subscribe(
        res => {
           var reportes = res;
           this.reportes.reverse();

           // Filtra reportes si es delegado
           console.log(this.isAdministrador)
           console.log()

           if(this.isAdministrador){
             this.reportes = reportes;
           }else{
            for(var i=0; i<reportes.length; i++){
              if(reportes[i].id_equipoA == +localStorage.getItem('id_equipo')){
                this.reportes.push(reportes[i]);
              }
           }
           
          }
           this.loading = false;
         },
         err => console.error(err)
      );
  }

  getReporte(id: string) {
    this.reporteService.getReporte(id).subscribe(
      res => {
        console.log("Exito");
      },
      err => console.error(err)
    );
  }

  deleteReporte(reporte: Reporte) {
    Swal.fire({
      title: "Eliminar Reporte",
      text: "Estás seguro que deseas eliminar el reporte con la siguiente descripción: " + reporte.descripcion + " ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí. eliminar!'
    }).then((result) => {
      if (result.value) {
        this.reporteService.deleteReporte(reporte.id).subscribe(
          res => {
            Swal.fire(
              'Eliminado!',
              'El reporte ha sido eliminado',
              'success'
            )
            this.getReportes();
          },
          err => console.error(err)
        )
      }
    });
    // this.getReportes();
  }

  // getter para acceder a los campos del formulario
  get f() { return this.reporteForm.controls; }

  get f2() { return this.castigoForm.controls; }

  public openPostModal() {
    this.reporteForm.reset();
    this.modalTitleText = 'Registrar Reporte';
    $("#reporteModal").modal("show");
  }

  onSubmit() {
    this.submitted = true;
    
    if(!this.isAdministrador){
      this.reporteForm.controls['id_equipoA'].setValue(this.id_equipoA);
    }

    if (this.reporteForm.invalid) {
      console.log(this.reporteForm);
      swal.fire({
        title: 'Todos los campos deben ser llenados',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }

    this.loading = true;
    
    this.reporteForm.controls['fecha_reporte'].setValue(new Date());

      if (this.modalTitleText === 'Registrar Reporte') {
        console.log(this.reporteForm.value)
          this.postOrPutReporte = this.reporteService.createReporte(this.reporteForm.value)
      }
      else {
        console.log(this.reporteForm.value)
          this.postOrPutReporte = this.reporteService.updateReporte(this.reporteForm.value)
      }


    this.postOrPutReporte
       .pipe(first())
       .subscribe(
         response => {
           swal.fire({
             title: 'Correcto!',
             text: response.message,
             type: 'success',
             confirmButtonText: 'ok'
           }),
           
           $("#reporteModal").modal("hide");
           this.reporteForm.reset();
           this.submitted = false;
           this.loading = false;
           this.getReportes();
         },
         error => {
           // pending to show error
           console.error(error);
           this.loading = false;
         }
       )
      //  this.getReportes();
  }

  public updateReporte(reporte: Reporte) {
    this.modalTitleText = 'Editar Reporte: ';

    console.log(reporte);

    this.reporteForm.controls['id'].setValue(reporte.id);
    this.reporteForm.controls['id_cancha'].setValue(reporte.id_cancha);
    this.reporteForm.controls['id_equipoA'].setValue(reporte.id_equipoA);
    this.reporteForm.controls['id_equipoB'].setValue(reporte.id_equipoB);
    this.reporteForm.controls['fecha_reporte'].setValue(reporte.fecha_reporte);
    this.reporteForm.controls['fecha_incidente'].setValue(reporte.fecha_incidente);
    this.reporteForm.controls['descripcion'].setValue(reporte.descripcion);
    this.reporteForm.controls['estatus'].setValue(true);

    $("#reporteModal").modal("show");
  }


  /*
    Funciones auxiliares
  */
  getEquipos() {
    this.loading = true;
      this.equipoService.getAllTabla().subscribe(
          res => {
              // this.equipos = res;
              this.equipos = this.ordenaEquipos(res);
              this.loading = false;
          },
          err => console.error(err)
      );

      // Poblar equipos_reporte
      this.equipoService.getAllTabla().subscribe(
        res => {
            var rol = localStorage.getItem('rol');
            if(rol == 'ADMINISTRADOR'){
              this.equipos_reporte = this.ordenaEquipos(res);
            }else{
              this.isAdministrador = false;
              this.id_equipoA = +localStorage.getItem('id_equipo');
            }
          },
          err => console.error(err)
        );
      }

  getCanchas() {
       this.canchaService.getCanchas().subscribe(
          res => {
            this.canchas = res;   
          },
          err => console.error(err)
        );
    }

    mostrarDescripcion(descripcion: string){
      Swal.fire(
        'Reporte:',
        descripcion
      )
    }

    ordenaEquipos(equipos: Equipo[]){
      // this.loading = true;
      var tems = [];
      var min: Equipo;
      var k = 0;
      for(var i=0; i<equipos.length;i++){
        for(var l = 0; l < equipos.length; l++){
          if(equipos[l].estatus != -1){
            min = equipos[l];
            k = l;
          }
        }
        for(var j=0; j<equipos.length;j++){
          if(min.nombre_equipo > equipos[j].nombre_equipo && equipos[j].estatus != -1){
            min = equipos[j];
            k = j;
          }
        }
        tems.push(min);
        equipos[k].estatus = -1;
      }
      return tems;
    }

    // AGREGAR CASTIGO

    agregarCastigo(reporte: Reporte){
      this.id_reporte_castigo = reporte.id;
      this.id_rival_castigo = reporte.id_equipoB;
      this.getJugadores();
      $("#castigoModal").modal("show");

    }

    getJugadores() {
      this.jugadorService.getJugadores(this.id_rival_castigo).subscribe(
      // this.jugadorService.getJugadores().subscribe(
          res => {
            this.jugadores = res;
            // this.getJugadorEquipo(res);
          },
          err => console.error(err)
      );
    }

    getJugadorEquipo(jugadores: Jugador[]) {
      this.loading = true;
      var jugador_equipo = [];
      console.log("1")
      this.jugadorEquipoService.getJugadorEquipo().subscribe(
          res => {
            jugador_equipo = res;
  
            console.log(jugador_equipo);
            console.log(this.id_rival_castigo);
            console.log(jugadores);
  
            for(var i=0; i<jugadores.length;i++){
              for(var j=0; j<jugador_equipo.length;j++){
                if(jugadores[i].id == jugador_equipo[j].id_jugador){
                  if(this.id_rival_castigo == jugador_equipo[j].id_equipo){
                    this.jugadores.push(jugadores[i]);
                  }
                }
              }
            }
            
            
  
            this.loading = false;
          },
          err => console.error(err)
        );
    }

  onSubmitCastigo() {
    this.castigoForm.value.id_reporte = this.id_reporte_castigo;
    this.castigoForm.value.id_equipo = this.id_rival_castigo;

    console.log(this.castigoForm.value);

    // return;

    this.submitted = true;


    if (this.castigoForm.invalid) {
        console.log(this.castigoForm);
        swal.fire({
            title: 'Todos los campos deben ser llenados',
            text: "",
            type: 'warning',
            confirmButtonText: 'ok'
        })
        return;
    }

        this.postOrPutCastigo = this.castigoService.createCastigo(this.castigoForm.value)
        console.log(this.castigoForm.value)

         this.postOrPutCastigo
           .pipe(first())
           .subscribe(
             response => {
               swal.fire({
                 title: 'Correcto!',
                 text: response.message,
                 type: 'success',
                 confirmButtonText: 'ok'
               }),
              //  this.getCastigos();
               $("#castigoModal").modal("hide");
               this.castigoForm.reset();
               this.submitted = false;
               this.loading = false;
             },
             error => {
               // pending to show error
               console.error(error);
               this.loading = false;
             }
           )
    
}


  fecha_actual() {

    let fecha_actual = new Date();
    let mes_letra: string;

    let mes_numero = (fecha_actual.getMonth() + 1);

    switch (mes_numero) {
      case 1:
        mes_letra = "Enero";
        break;
      case 2:
        mes_letra = "Febrero";
        break;
      case 3:
        mes_letra = "Marzo";
        break;
      case 4:
        mes_letra = "Abril";
        break;
      case 5:
        mes_letra = "Mayo";
        break;
      case 6:
        mes_letra = "Junio";
        break;
      case 7:
        mes_letra = "Julio";
        break;
      case 8:
        mes_letra = "Agosto";
        break;
      case 9:
        mes_letra = "Septiembre";
        break;
      case 10:
        mes_letra = "Octubre";
        break;
      case 11:
        mes_letra = "Noviembre";
        break;
      case 12:
        mes_letra = "Diciembre";
        break;
      default:
        mes_letra = "n/a";
    }

    let fecha = fecha_actual.getDate() + '-' + mes_letra + '-' + fecha_actual.getFullYear();
    let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();

    return fecha + ' ' + hora;
  }
}


import { Component, OnInit } from '@angular/core';
import { CarrouselService } from 'src/app/_services/carrousel.service';
import { TablaCarrusel } from '../../_models/tabla_carrusel';
import { Equipo } from 'src/app/_models/equipo';
import { Torneo } from 'src/app/_models/torneo';

import { Aviso } from '../../_models/aviso';
import { AvisoService } from '../../_services/aviso.service';

declare var $ :any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  title = 'Futbolisoo';
  partidos = [];
  torneos = [];
  equipos: Equipo[];
  categorias = [];
  loading = false;
  tabla_carrusel: TablaCarrusel[];

  avisos: Aviso[];

  facebook = "";

  constructor( private carrouselService: CarrouselService, private avisoService: AvisoService) { 
    
  }

  ngOnInit() {  
      // this.getTorneos();
      this.getAvisosHome();
  }

   getTorneos() {
    this.loading = true;
    this.torneos = [];
    this.carrouselService.getTorneos().subscribe(
        res => {
          this.torneos = res;         
        },
        err => console.error(err)
      );
  }

  cargaCarruselPos(torneo: Torneo){
    console.log(this.torneos);
 //   this.tabla_carrusel = [];
        //  for(var torneo of this.torneos) {
            this.carrouselService.getInfoCarrousel(torneo).subscribe(
                   res => {
                   this.equipos = res;
                   console.log(this.equipos);
                   }, 
                 err => console.error(err)
               );   
  }

  getAvisosHome(){
    let avisosAux: Aviso[];
    this.avisos = [];
    this.loading = true;
    this.avisoService.getAvisosHome().subscribe(
        res => {
          console.log(res);
          avisosAux = res;
          for(var i=0; i<avisosAux.length; i++){
            this.avisos[avisosAux.length-i-1] = avisosAux[i];
          }
          this.loading = false;
        },
        err => console.error(err)
      );
  }

  fecha_actual(){ 
    
    let fecha_actual = new Date();
    let mes_letra: string;
  
    let mes_numero = (fecha_actual.getMonth()+1);
  
    switch(mes_numero){
      case 1:
        mes_letra = "Enero";
        break;
      case 2:
        mes_letra = "Febrero";
        break;
      case 3:
        mes_letra = "Marzo";
        break;
      case 4:
        mes_letra = "Abril";
        break;
      case 5:
        mes_letra = "Mayo";
        break;
      case 6:
        mes_letra = "Junio";
        break;
      case 7:
        mes_letra = "Julio";
        break;
      case 8:
        mes_letra = "Agosto";
        break;
      case 9:
        mes_letra = "Septiembre";
        break;
      case 10:
        mes_letra = "Octubre";
        break;
      case 11:
        mes_letra = "Noviembre";
        break;
      case 12:
        mes_letra = "Diciembre";
        break; 
      default:
          mes_letra = "n/a";
    }
  
    let fecha = fecha_actual.getDate() + '-' +  mes_letra + '-' + fecha_actual.getFullYear();
    let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();
    
  
    return fecha + ' ' + hora; 
  }

}

import { Component, OnInit } from '@angular/core';

import { Aviso } from '../../_models/aviso';
import { AvisoService } from '../../_services/aviso.service';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2';
declare var $ :any;

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.css']
})
export class AvisoComponent implements OnInit {

  avisos: Aviso[];

  avisoForm: FormGroup;
  loading = false;
  submitted = false;
  flag_editar = false;
  modalTitleText: String;
  postOrPutAviso;
  searchText;

  pp: any;

  constructor(private avisoService: AvisoService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.avisoForm = this.formBuilder.group({
      id: [''],
      descripcion: ['', Validators.required],
      fecha: ['']
    })

    this.getAvisos();
  }

  getAvisos(){
    this.avisos = [];
    this.loading = true;
    this.avisoService.getAvisos().subscribe(
        res => {
          this.avisos = res;
          this.loading = false;
        },
        err => console.error(err)
      );
  }

  deleteAviso(aviso: Aviso){
    Swal.fire({
      title: "Eliminar Aviso",
      text: "Estás seguro que deseas eliminar el aviso?", 
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí. eliminar!'
    }).then((result) => {
      if(result.value){
        this.avisoService.deleteAviso(aviso.id).subscribe(
          res => {
            Swal.fire(
              'Eliminado!',
              'El aviso ha sido eliminado',
              'success'
            )
            this.getAvisos();
          },
          err => console.error(err)
        )
      }
    });
  }

  updateAviso(aviso){
    this.avisoService.updateAviso(aviso);
  }

  public openPostModal(){
    this.avisoForm.reset();
    this.modalTitleText = 'Registrar Horario';
    this.flag_editar = false;
    $("#avisoModal").modal("show");
  }

  onSubmit(){
    this.submitted = true;
  
    if(this.avisoForm.invalid){
      console.log(this.avisoForm);
      swal.fire({
        title: 'Todos los campos deben ser llenados',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }
  
    this.loading = true;
    
    var create = this.avisoService.createAviso(this.avisoForm.value)
    console.log(this.avisoForm.value)

    create.pipe(first())
    .subscribe(
      response => {
  
        swal.fire({
          title: 'Correcto!',
          text: 'El aviso ha sido registrado!',
          type: 'success',
          confirmButtonText: 'ok'
        }),
        this.getAvisos();
        $("#avisoModal").modal("hide");
        this.avisoForm.reset();
        this.submitted = false;
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    )
  }

  // getter para acceder a los campos del formulario
  get f() { return this.avisoForm.controls; }


  fecha_actual() {

    let fecha_actual = new Date();
    let mes_letra: string;

    let mes_numero = (fecha_actual.getMonth() + 1);

    switch (mes_numero) {
        case 1:
            mes_letra = "Enero";
            break;
        case 2:
            mes_letra = "Febrero";
            break;
        case 3:
            mes_letra = "Marzo";
            break;
        case 4:
            mes_letra = "Abril";
            break;
        case 5:
            mes_letra = "Mayo";
            break;
        case 6:
            mes_letra = "Junio";
            break;
        case 7:
            mes_letra = "Julio";
            break;
        case 8:
            mes_letra = "Agosto";
            break;
        case 9:
            mes_letra = "Septiembre";
            break;
        case 10:
            mes_letra = "Octubre";
            break;
        case 11:
            mes_letra = "Noviembre";
            break;
        case 12:
            mes_letra = "Diciembre";
            break;
        default:
            mes_letra = "n/a";
    }

  }

}

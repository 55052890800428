import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Equipo } from '../_models/equipo';
import { Categoria } from '../_models/categoria';
import { Torneo } from '../_models/torneo';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CarrouselService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  getInfoCarrousel(torneo: Torneo) {
    return this.http.post<Equipo[]>(this.baseUrl + 'carrousel/equipos_torneo', torneo);
  }

  getTorneos() {
    return this.http.get<Torneo[]>(this.baseUrl + `carrousel/torneo`);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Impreg } from '../_models/impreg';
import { Registros } from '../_models/registros';
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ImpregService {

  private baseUrl = GlobalConstants.apiURL;

  constructor(private http: HttpClient) { }

  createImpreg(impreg: number[]) {
    return this.http.post<Registros>(this.baseUrl+'impreg/', impreg);
  }
}

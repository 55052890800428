

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DatesHelper {
    constructor( ){}

    fecha_actual(): String{
    
        let fecha_actual = new Date();
        let mes_letra: string;
      
        let mes_numero = (fecha_actual.getMonth()+1);
      
        switch(mes_numero){
          case 1:
            mes_letra = "Enero";
            break;
          case 2:
            mes_letra = "Febrero";
            break;
          case 3:
            mes_letra = "Marzo";
            break;
          case 4:
            mes_letra = "Abril";
            break;
          case 5:
            mes_letra = "Mayo";
            break;
          case 6:
            mes_letra = "Junio";
            break;
          case 7:
            mes_letra = "Julio";
            break;
          case 8:
            mes_letra = "Agosto";
            break;
          case 9:
            mes_letra = "Septiembre";
            break;
          case 10:
            mes_letra = "Octubre";
            break;
          case 11:
            mes_letra = "Noviembre";
            break;
          case 12:
            mes_letra = "Diciembre";
            break; 
          default:
              mes_letra = "n/a";
        }
    
        let fecha = fecha_actual.getDate() + '-' +  mes_letra + '-' + fecha_actual.getFullYear();
        let hora = fecha_actual.getHours() + ":" + fecha_actual.getMinutes() + ":" + fecha_actual.getSeconds();

        return fecha + ' ' + hora; 
    }
}
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2'; 


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  email = '';
  password = '';
  textButton = '';
  isLoading = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      'email' : [null, Validators.required],
      'password' : [null, Validators.required]
    });
    this.textButton = "Ingresar";
  }

  onSubmit(form) {
    this.isLoading = true;
    this.textButton = "Ingresando...";
    let username: String;

    if(this.loginForm.invalid){
      swal.fire({
        title: 'Todos los campos deben ser llenados',
        text: "",
        type: 'warning',
        confirmButtonText: 'ok'
      })
      return;
    }

    let login = this.authService.login(form.value);
    login
     .pipe(first())
      .subscribe(res => {
        if (res.token) {
          localStorage.setItem('token', res.token);
          localStorage.setItem('rol', res.rol);
          localStorage.setItem('id', res.id);
          localStorage.setItem('id_equipo', res.id_equipo);
          localStorage.setItem('username', res.username);
          
          username = localStorage.getItem('username');        
          

          swal.fire({
            title: 'Sesión Iniciada',
            text: "Bienvenido " + username.substring(0, username.indexOf('@')),
            position: 'top-end',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          })
          this.isLoading = false;
          this.textButton = "Ingresar";
          
          if(res.rol == "DELEGADO"){
            this.router.navigate(['/equipo-detalle/'+res.id_equipo]);
          }else
            this.router.navigate(['']);
        }
        
      }, (err) => {
        console.log(err);
        this.isLoading = false;
        this.textButton = "Ingresar";
      });
  }

  register() {
    this.router.navigate(['register']);
  }


}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { Torneo } from '../../_models/torneo';
import { Equipo } from '../../_models/equipo';
import { TablaPosicion } from '../../_models/tabla-posicion';
import { TorneoService } from '../../_services/torneo.service';
import { EquipoService } from '../../_services/equipo.service';
import { PartidoService } from '../../_services/partido.service';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2';

import { first } from 'rxjs/operators';

@Component({
  selector: 'app-torneo-grupo',
  templateUrl: './torneo-grupo.component.html',
  styleUrls: ['./torneo-grupo.component.css']
})
export class TorneoGrupoComponent implements OnInit {

  id_torneo: any;
  torneo: Torneo;
  torneos = [];
  torneoSeleccionado: any;
  equipos = [];
  
  tabla_posiciones: TablaPosicion[] = [];
  tabla_posiciones0: TablaPosicion[] = [];
  tabla_posiciones1: TablaPosicion[] = [];

  tb = false;
  tb0 = false;
  tb1 = false;

  isAdmin = false;
  loading = false; 

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private equipoService: EquipoService, 
    private torneoService: TorneoService, 
    private partidoService: PartidoService,
  ) { }

  ngOnInit() {
      this.id_torneo = this.route.snapshot.paramMap.get('id');
      this.isAdmin = true;

      console.log(this.id_torneo);
      this.getTorneo(this.id_torneo);
  }

  getTorneo(id) {
    this.torneoService.getTorneo(id).subscribe(
        res => {
          this.torneo = res;
          console.log(this.torneo);
          //this.getEquipos(this.torneo.id_categoria);
          this.getTorneosByTipoAndIdCategoria(this.torneo.id_categoria);
        },
        err => console.error(err)
      );
  }

  getTorneosByTipoAndIdCategoria(id_categoria){
    this.loading = true;
    this.torneos = [];
    this.torneoService.getTorneosByTipoAndIdCategoria(1, id_categoria).subscribe(
        res => {
          this.torneos = res;
          this.loading = false;
          console.log(this.torneos);
        },
        err => console.error(err)
      );
  }

  getEquipos(id_categoria) {
    this.loading = true;
    this.equipos = [];
    this.equipoService.getEquipoCategoria(id_categoria).subscribe(
        res => {
          this.equipos = res;
          this.loading = false;
          console.log(this.equipos);
        },
        err => console.error(err)
      );
  }

  getTablaPosiciones(){
    this.loading = true;
    this.tabla_posiciones = [];
    console.log(this.torneoSeleccionado);
    this.partidoService.getTablaPosiciones(this.torneoSeleccionado)
    .pipe(first())
    .subscribe(res => {
        console.log(res);
        this.tabla_posiciones = res;
        this.tabla_posiciones = this.ordenaTablaPosiciones(this.tabla_posiciones);
        this.tb = true;
        this.loading = false;

        console.log(this.tabla_posiciones);
      },
        error => {
          console.error(error);
        }
    );
  }

  ordenaTablaPosiciones(tabla_posiciones){
    // Ordena posiciones por pts, dg, gf
    var posiciones: TablaPosicion[] = [];
    var max: TablaPosicion;
    var k = 0;

    for(var j = 0; j < tabla_posiciones.length; j++){
      for(var l = 0; l < tabla_posiciones.length; l++){
        if(tabla_posiciones[l].id_equipo != -1){
          max = tabla_posiciones[l];
          k = l;
        }
      }
      for(var i = 0; i < tabla_posiciones.length; i++){
        if(max.pts < tabla_posiciones[i].pts && tabla_posiciones[i].id_equipo != -1){
          max = tabla_posiciones[i];
          k = i;
        }else 
        if(max.pts == tabla_posiciones[i].pts && tabla_posiciones[i].id_equipo != -1){
          if(max.gd < tabla_posiciones[i].gd){
            max = tabla_posiciones[i];
            k = i;
          }else
          if(max.gd == tabla_posiciones[i].gd){
            if(max.gf < tabla_posiciones[i].gf){
              max = tabla_posiciones[i];
              k = i;
            }
          }
        }
      }
      posiciones.push(max);
      tabla_posiciones[k].id_equipo = -1;
    }

    tabla_posiciones = posiciones;

    for(var i = 0; i < tabla_posiciones.length; i++){
      tabla_posiciones[i].id_equipo = i+1;
    }
    return posiciones;
  }

  setGrupos(){
    this.loading = true;

    this.torneoService.setGrupos(this.tabla_posiciones)
      .pipe(first())
      .subscribe(
        response => {
          swal.fire({
            title: 'Correcto!',
            text: "Los grupos fueron asignados exitosamente!",
            type: 'success',
            confirmButtonText: 'ok'
          }),
          this.loading = false;
        },
        error => {
          console.error(error);
          this.loading = false;
        }
      )
  }

  regresarATorneos(){
    this.router.navigate(['torneo']);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReglamentoComponent } from './reglamento/reglamento.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { MainComponent } from './components/main/main.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { EquipoComponent } from './components/equipo/equipo.component';
import { EquipoDetalleComponent } from './components/equipo-detalle/equipo-detalle.component';
import { EquipoRegistroComponent } from './components/equipo-registro/equipo-registro.component';
import { JugadorComponent } from './components/jugador/jugador.component';
import { JugadorDetalleComponent } from './components/jugador-detalle/jugador-detalle.component';
import { TorneoComponent } from './components/torneo/torneo.component';
import { HorarioComponent } from './components/horario/horario.component';
import { CategoriaComponent } from './components/categoria/categoria.component';
import { TablaPosicionComponent } from './components/tabla-posicion/tabla-posicion.component';
import { PartidoComponent } from './components/partido/partido.component';
import { ReporteComponent } from './components/reporte/reporte.component';
import { CastigoComponent } from './components/castigo/castigo.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { RolJuegosComponent } from './components/rol-juegos/rol-juegos.component';
import { TorneoGrupoComponent } from './components/torneo-grupo/torneo-grupo.component';

const routes: Routes = [
  { path: '', component: MainComponent},
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'usuario', component: UsuarioComponent},
  { path: 'equipo', component: EquipoComponent},
  { path: 'equipo-detalle/:id' , component: EquipoDetalleComponent},
  { path: 'equipo-registro' , component: EquipoRegistroComponent},
  { path: 'jugador', component: JugadorComponent},
  { path: 'jugador/:id' , component: JugadorDetalleComponent},
  { path: 'jugador/:id/:id_categoria' , component: JugadorComponent},
  { path: 'torneo', component: TorneoComponent},
  { path: 'horario', component: HorarioComponent},
  { path: 'tabla_posicion', component: TablaPosicionComponent },
  { path: 'reporte', component: ReporteComponent },
  { path: 'castigo', component: CastigoComponent },
  { path: 'partido', component: PartidoComponent},
  { path: 'categoria', component: CategoriaComponent },
  { path: 'reglamento', component: ReglamentoComponent },
  { path: 'aviso', component: AvisoComponent },
  { path: 'rol-juegos', component: RolJuegosComponent },
  { path: 'torneo-grupo/:id', component: TorneoGrupoComponent },

  // de otra forma, redirecciona al home
  { path: '**', redirectTo: ''}
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
